/*
 * @Author: ttheitao
 * @Description: 数据工具类
 * @Date: 2022-05-26 17:46:19
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-08 15:54:03
 */
import { collect } from 'collect.js';
export default class Tool {
  static ruleEdgeStoke = {
    pass: '#67C23A',
    fail: '#F56C6C'
  }

  /**
   * @description: 获取一个随机数
   * @param {Number} min 最大值
   * @param {Number} max 最小值
   * @return {Number}
   */
  static randomRange(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  /**
   * @description: 深拷贝
   * @param {Object|Array} target
   * @return {mixed}
   */
  static deepCopy(target) {
    if (typeof target == 'object') {
      let result = Array.isArray(target) ? [] : {};
      for (let key in target) {
        if (typeof target[key] == 'object' && target[key]) {
          result[key] = Tool.deepCopy(target[key]);
        } else {
          result[key] = target[key];
        }
      }
      return result;
    }
    return target;
  }

  /**
   * 获取当前节点下边的最大优先级
   * @param {Object} node 
   * @param {Object} cells 
   * @returns 
   */
  static getMaxPriorityByNode(node, cells) {
    let priorities = [0];

    cells.forEach(element => {
      if (['RuleEdge', 'RunEdge'].includes(element.shape) && element.source.cell === node.id) {
        priorities.push(element.data.priority);
      }
    });

    return Math.max(...priorities);
  }

  // 指定长度和基数
  static uuid2(len = 20, radix = 16) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
      i;
    radix = radix || chars.length;

    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
      // rfc4122, version 4 form
      var r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }

    return uuid.join('').toLowerCase();
  }

  /**
   * 转换后端返回cell的数据结构为x6的结构
   * @param {*} dbCells 后端返回的cell结构
   * @returns 
   */
  static dbToX6StyleCell(dbCells) {
    let x6Cells = [];
    if (Array.isArray(dbCells)) {
      dbCells.forEach((cell) => {
        x6Cells.push(cell.prop);
      });
    }
    return x6Cells;
  }

  /**
   * @description: 只需要几个key
   * @param {Array} data
   * @param {Array} codes
   * @param {Boolean} needCollection 
   * @return {Array}
   */
  static only(data, codes, needCollection = true) {
    let res = []
    for (let i = 0; i < data.length; i++) {
      let item = {}
      for (let key in codes) {
        item[codes[key]] = data[i][codes[key]]
      }
      res.push(item)
    }
    if (needCollection) {
      return collect(res)
    } else {
      return res
    }
  }
}

