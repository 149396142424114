/*
 * @Author: ttheitao
 * @Description: 业务工具类
 * @Date: 2022-07-04 17:54:37
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-27 22:07:38
 */

import Constant from "./Constant";
import Tool from "./Tool";

export default class Util {
  /**
   * @description: 根据单元获取vm实例
   * @param {*} graph 画布实例
   * @param {*} cell 单元
   * @return {*}
   */
  static getVmByCell(graph, cell) {
    let vm = null;
    if (cell.view === 'vue-shape-view') {
      const view = graph.findViewByCell(cell);
      vm = view ? view.vm.$children[0] : null;

      if(vm && !vm.openPanel){
        vm = vm.$children[0];
      }
    }
    return vm;
  }

  /**
   * 转换后端返回cell的数据结构为x6的结构
   * @param {*} dbCells 后端返回的cell结构
   * @returns 
   */
  static dbToX6StyleCell(dbCells) {
    const x6Cells = [];
    if (Array.isArray(dbCells)) {
      dbCells.forEach((cell) => {
        x6Cells.push(cell.prop);
      });
    }
    return x6Cells;
  }

  /**
   * @description: 生成一个新的 ObjectFields 结构，替换origin
   * @param {Object} node 节点
   * @return {Array}
   */
  static generateObjectFields(node) {
    let ret = [];
    let objectFields = node.data.objectFields;

    if (Util.isEtlFieldConfigNode(node)) {
      objectFields = node.data.objectFields.filter(item => {
        return item.show;
      });
    }

    for (let i in objectFields) {
      let temp = Tool.deepCopy(objectFields[i]);
      temp.origin = `${node.id}.${objectFields[i].code}`;
      temp.uuid && delete temp.uuid;
      temp.aggregate && delete temp.aggregate;
      ret.push(temp);
    }

    return ret;
  }


  /**
   * @description: 是否为输入节点
   * @param {*} node 节点
   * @return {boolean}
   */
  static isEtlInputNode(node) {
    return node.component === Constant.ETL_CELL_INPUT;
  }

  /**
   * @description: 是否为输入节点
   * @param {*} node 节点
   * @return {boolean}
   */
  static isEtlOutputNode(node) {
    return node.component === Constant.ETL_CELL_OUTPUT;
  }

  /**
   * @description: 是否为多源合并节点
   * @param {*} node 节点
   * @return {boolean}
   */
  static isEtlUnionAllNode(node) {
    return node.component === Constant.ETL_CELL_UNIONALL;
  }

  /**
   * @description: 是否为横向连接节点
   * @param {*} node 节点
   * @return {boolean}
   */
  static isEtlJoinNode(node) {
    return node.component === Constant.ETL_CELL_JOIN;
  }

  /**
   * @description: 是否为数据筛选节点
   * @param {*} node 节点
   * @return {boolean}
   */
  static isEtlFilterNode(node) {
    return node.component === Constant.ETL_CELL_FILTER;
  }

  /**
   * @description: 是否为字段配置节点
   * @param {*} node 节点
   * @return {boolean}
   */
  static isEtlFieldConfigNode(node) {
    return node.component === Constant.ETL_CELL_FIELD_CONFIG;
  }

  /**
   * @description: 是否为分组汇总节点
   * @param {*} node 节点
   * @return {boolean}
   */
  static isEtlAggregateNode(node) {
    return node.component === Constant.ETL_CELL_AGGREGATE;
  }

  /**
   * @description: 清空ETL数据筛选左侧对象
   * @param {Object} node 节点
   * @return {Boolean}
   */
  static clearEtlJoinLeftObject(node) {
    return Util.clearEtlJoinObject(node, 1);
  }

  /**
  * @description: 清空ETL数据筛选左侧对象
  * @param {Object} node 节点
  * @return {Boolean}
  */
  static clearEtlJoinRightObject(node) {
    return Util.clearEtlJoinObject(node, 2);
  }

  /**
   * @description: 清空ETL数据筛选对象
   * @param {Object} node 节点
   * @param {Number} type 类型：1 - 清空左侧，2 - 清空右侧
   * @return {Boolean}
   */
  static clearEtlJoinObject(node, type) {
    let newOfs = [];

    if (type === 1 && !node.data.rightObjectUuid) {
      newOfs = [];
    } else if (type === 2 && !node.data.leftObjectUuid) {
      newOfs = [];
    } else {
      newOfs = node.data.objectFields.filter((item) => {
        // 取反向数据保留
        return item.origin.includes(type === 1 ? node.data.rightObjectUuid : node.data.leftObjectUuid);
      });
    }

    if (type === 1) {
      node.updateData({ leftObjectUuid: '', objectFields: newOfs });
    } else {
      node.updateData({ rightObjectUuid: '', objectFields: newOfs });
    }

    return true;
  }

  /**
  * @description: 清空ETL横向连接 on 条件
  * @param {Object} node 节点
  * @return {Boolean}
  */
  static clearEtlJoinOnList(node) {
    node.updateData({ joinOnList: [] });
  }

  /**
   * @description: 清空ETL数据筛选配置
   * @param {*} node
   * @return {*}
   */
  static clearEtlFilterConfig(node) {
    node.updateData({ conditions: [], objectFields: [] });
  }
}