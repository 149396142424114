<!--
 * @Author: ttheitao
 * @Description: 字段配置
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-23 16:00:08
-->

<template>
  <div class="etl-cell-container">
    <div class="content">
      <i class="iconfont iconziduanpeizhi"></i>
      {{ nodeData.label }}
    </div>
    <el-drawer
      class="etl-cell-panel etl-cell-field-config-panel"
      :visible.sync="showPanel"
      :append-to-body="panel.modalAppendToBody"
      :modal="panel.modal"
      :direction="panel.direction"
      :size="panel.size"
      :show-close="panel.showClose"
      @close="onDrawerClose"
    >
      <template slot="title">
        <div class="type">
          <i class="iconfont iconziduanpeizhi"></i>
          字段配置
        </div>
        <div class="name">
          <span>节点名称：</span>
          <el-input v-model="nodeData.label"></el-input>
        </div>
      </template>
      <div class="main">
        <div class="config-content">
          <div class="actions">
            <el-popover placement="top" width="200" class="overflow-y-auto" popper-class="elt-popover" style="overflow-y:auto;max-height: 900px;">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                style="display: block; margin-bottom: 10px"
              >
                全选
              </el-checkbox>
              <el-checkbox
                v-for="(item, index) in nodeData.objectFields"
                :key="index"
                v-model="item.show"
                @change="handleCheckedChange"
                style="display: block; margin-bottom: 10px"
              >
                {{ item.name }}
              </el-checkbox>
              <div slot="reference" class="primary-btn">
                <i class="iconfont iconyulan"></i> 显示原始字段
              </div>
            </el-popover>
            <div
              style="margin-left: 20px"
              class="primary-btn"
              @click="onOpenValueForm(null)"
            >
              <i class="iconfont iconxinzeng2"></i> 添加计算字段
            </div>
          </div>
          <div class="scroll-div">
            <draggable v-model="nodeData.objectFields" class="columns">
              <template v-for="(item, index) in nodeData.objectFields">
                <div class="col" :key="index" v-if="item.show">
                  <div class="text">
                    <span
                      @click.stop="onEditFieldName(index,item)"
                      :class="item.fieldType === 2 ? 'is-formula' : ''"
                    >
                      <i class="iconfont icona-wendangjilu"></i>
                      {{ item.name }}
                    </span>
                    <span class="drag-btn"><i class="el-icon-rank"></i></span>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 虚拟字段弹窗 -->
    <el-dialog
      class="etl-field-dialog"
      :title="fieldIndex === null ? '新增计算字段' : '修改计算字段：'+fieldName"
      v-if="showValueBase"
      :visible.sync="showValueBase"
      :append-to-body="true"
      width="800px"
    >
      <ValueForm
        v-model="fieldFormula"
        realTime
        :obuuid="obuuid"
        :formData="fieldFormula"
        :needTitle="false"
        class="value-form"
        :fieldList="fieldList"
      ></ValueForm>
      <el-form
        label-width="80px"
        label-position="top"
        class="form-class"
        ref="dialog"
        >
          <el-form-item label="计算字段名称">
            <el-input class='form-input' v-model="fieldName"></el-input>  
          </el-form-item>
          <el-form-item label="计算字段code">
            <el-input class='form-input' v-model="fieldCode"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onSaveFormula" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改字段数据 -->
    <el-dialog
    :append-to-body="true"
      :visible.sync='editDialog'
      width="800px"
      title="修改字段数据"
    >
        <el-form
        v-if="editData"
        label-width="80px"
        :model="editData"
        label-position="top"
        class="form-class"
        ref="dialog"
        >
          <el-form-item label="修改字段名称">
            <el-input class='form-input' v-model="editData.name"></el-input>  
          </el-form-item>
          <el-form-item label="修改字段code">
            <el-input class='form-input' v-model="editData.code"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button size="mini" @click="onEditClose">取消</el-button>
            <el-button size="mini" type="primary" @click="onEditSave">保存</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseOptions from "./mixins/BaseOptions.js";
import { Popover, Message } from "element-ui";
import draggable from "vuedraggable";
import ValueForm from "@/views/objects/components/valueForm.vue";
import Constant from "../libs/Constant";
import Tool from "../libs/Tool";
import { deepCopy } from '@/utils/tools';

export default {
  name: "EtlfieldFormula",
  mixins: [BaseOptions],
  components: {
    "el-popover": Popover,
    draggable: draggable,
    ValueForm,
  },
  data() {
    return {
      tableData: [],
      checkAll: false,
      isIndeterminate: true,

      // 计算字段配置
      fieldName: "",
      fieldCode: "",
      fieldFormula: null,
      fieldIndex: null,

      showValueBase: false,
      obuuid: '',

      // 当前修改的数据
      editDataIndex:null,
      editData:null,
      editDialog:false,
    };
  },
  computed:{
    fieldList(){
      let arr = []
      for (let i = 0; i < this.nodeData.objectFields.length; i++) {
        let item = this.nodeData.objectFields[i];
        if(item.fieldType !== Constant.ETL_FIELD_COMPUTE && item.show){
          item.uuid = item.code
          arr.push(item);
        } 
      }
      return arr;
    }
  },
  mounted() {
    const GetRequest = () => {
      var url = location.search; //获取url中"?"符后的字串
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      let strs = str.split("&");
      for(var i = 0; i < strs.length; i ++) {
      theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
      }
      }
      return theRequest;
    }
    this.obuuid = GetRequest().obuuid
  },
  methods: {
    handleCheckAllChange(val) {
      this.nodeData.objectFields.forEach((element) => {
        element.show = val;
      });
      this.isIndeterminate = false;
    },
    handleCheckedChange() {
      let checkedCount = this.nodeData.objectFields.filter((item) => {
        return item.show;
      }).length;
      this.checkAll = checkedCount === this.nodeData.objectFields.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.nodeData.objectFields.length;
    },

    /**
     * @description: 修改字段名称
     * @param {Number} index 字段在objectFields里面的序号
     * @param {String} name 原始名称
     */
    onEditFieldName(index,data) { 
      const field = data;
      if (field.fieldType == 2) {
        this.onOpenValueForm(index);
        return;
      }
      this.editData = deepCopy(data)
      this.editDataIndex = index
      this.editDialog = true
      // this.$prompt("", "修改字段名称", {
      //   inputValue: data.name,
      // })
      //   .then(({ value }) => {
      //     this.nodeData.objectFields[index].name = value;
      //   })
      //   .catch(() => {});
    },
    /**
     * @desc 关闭编辑字段弹窗
     */
    onEditClose(){
      this.editDialog = false
    },
    /**
     * @desc 保存编辑字段弹窗
     */
    onEditSave(){
      this.nodeData.objectFields[this.editDataIndex].name = this.editData.name
       this.nodeData.objectFields[this.editDataIndex].code = this.editData.code
      this.editDialog = false
    },
    /**
     * @description: 打开
     * @param {Number} index 字段在objectFields里面的序号
     */
    onOpenValueForm(index = null) {
      this.fieldIndex = index;
      
      if (index === null) {
        this.fieldName = "";
        this.fieldCode = `f${Tool.uuid2(9)}`
        this.fieldFormula = null;
      } else {
        const field = this.nodeData.objectFields[index];
        this.fieldName = field.name;
        this.fieldCode = field.code;
        this.fieldFormula = JSON.parse(JSON.stringify(field.formula));
      }

      this.showValueBase = true;
    },

    /**
     * @description: 保存虚拟字段配置
     */
    onSaveFormula() {
      console.log(this.nodeData.objectFields, "objecvifle");
      if (!this.fieldName) {
        Message.info("请填写字段名称！");
        return;
      }

      if (this.fieldIndex !== null) {
        // 修改
        let field = this.nodeData.objectFields[this.fieldIndex];
        field.name = this.fieldName;
        field.code = this.fieldCode
        field.formula = this.fieldFormula;
      } else {
        // 新增
        this.nodeData.objectFields.unshift({
          fieldType: Constant.ETL_FIELD_COMPUTE,
          code: this.fieldCode,
          formula: this.fieldFormula,
          name: this.fieldName,
          show: true,
          origin: "",
          type: "append",
        });
      }
      this.showValueBase = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../manage-views/css/manageAdd.less";

</style>
<style lang="less">
.elt-popover{
  overflow-y: auto;
  max-height: 500px;
}
.value-form {
  max-height: 60vh;
  overflow-y: auto;
}
.is-formula {
  color: #5a9cfe;
}
.etl-field-dialog {
  .el-dialog {
    margin-top: 0 !important;
  }
  .dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .field-name {
      width: 400px;
    }
  }
}
.etl-cell-field-config-panel {
  .config-content {
    flex: 1;
    width: 100%;
    .actions {
      padding: 5px 15px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      box-sizing: border-box;
    }

    .scroll-div {
      width: 100%;
      height: calc(100% - 50px);
      overflow-x: scroll;
      overflow-y: hidden;

      .columns {
        height: 100%;
        display: inline-flex;

        .col {
          width: 180px;
          height: 100%;
          border-right: 1px solid #e9e9e9;
          border-top: 1px solid #e9e9e9;
          border-bottom: 1px solid #e9e9e9;
          box-sizing: border-box;
          cursor: move;
          background: #fff;
          .text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 0 10px;
            border-bottom: 1px solid #e9e9e9;
            cursor: move;
            i {
              margin-right: 5px;
              cursor: move;
            }
          }
        }
      }
    }
  }
}
</style>