
import Constant from "../Constant";
import EtlDataHandler from "../dataHandler/EtlDataHandler";
import Util from "../Util";

/*
 * @Author: ttheitao
 * @Description: ETL事件处理器
 * @Date: 2022-09-08 16:11:23
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-09-29 15:57:20
 */
export default class EtlEventHandler {
  /**
   * @description: 构造函数
   * @param {Object} graph x6实例
   * @param {String} event 事件
   * @param {Object} args 参数
   * @return {void}
   */
  constructor(graph, event, args = null) {
    // x6画布对象
    this.graph = graph;
    // 事件
    this.event = event;
    // 参数
    this.args = args;
  }


  /**
   * @description: 创建链接验证器
   * @param {Object} graph x6实例
   * @param {String} event 事件
   * @param {Object} args 参数
   * @return {void}
   */
  static graph(graph, event, args = null) {
    return new EtlEventHandler(graph, event, args);
  }

  /**
   * @description: 删除边后
   * @param {Object} graph x6实例
   * @param {Object} args 参数
   * @return {Object}
   */
  static edgeRemoved(graph, args) {
    let handler = EtlEventHandler.graph(graph, 'edge:removed', args);
    handler.handleEdgeRemoved();
  }

  /**
   * @description: 数据修改后
   * @param {Object} graph x6实例
   * @param {Object} args 参数
   * @return {void}
   */
  static cellChangeData(graph, args) {
    let handler = EtlEventHandler.graph(graph, 'cell:change:data', args);
    handler.handleCellChangeData();
  }

  /**
   * @description: 节点删除后
   * @param {Object} graph x6实例
   * @param {Object} args 参数
   * @return {void}
   */
  static nodeRemoved(graph, args) {
    let handler = EtlEventHandler.graph(graph, 'node:removed', args);
    handler.handleNodeRemoved();
  }

  /**
   * @description: 节点新增后
   * @param {Object} graph x6实例
   * @param {Object} args 参数
   * @return {void}
   */
  static nodeAdded(graph, args) {
    let handler = EtlEventHandler.graph(graph, 'node:added', args);
    handler.handleNodeAdded();
  }

  /**
   * @description: 边连接后
   * @param {Object} graph x6实例
   * @param {Object} args 参数
   * @return {void}
   */
  static edgeConnected(graph, args) {
    let handler = EtlEventHandler.graph(graph, 'edge:added', args);
    handler.handleEdgeConnected();
  }


  /**
   * @description: 处理边新增后逻辑
   * @return {void}
   */
  handleEdgeConnected() {
    if (this.args.isNew) {
      EtlDataHandler.graph(this.graph, this.args.edge).apply();
    }
  }

  /**
   * @description: 处理边删除后的逻辑
   * @return {void}
   */
  handleEdgeRemoved() {
    let sourceNode = this.graph.getCellById(this.args.cell.source.cell);
    let targetNode = this.graph.getCellById(this.args.cell.target.cell);
    /*  横向连接相关变量 - 开始 */
    let leftObjectUuid = "";
    /* 横向连接相关变量 - 结束 */

    console.log(this.args.cell, 'handleEdgeRemoved');

    if (!targetNode) {
      return;
    }

    switch (targetNode.component) {
      case Constant.ETL_CELL_INPUT:
        break;
      case Constant.ETL_CELL_JOIN:
        leftObjectUuid = targetNode.data.leftObjectUuid;
        /**
         * 如果起始节点已被删除
         * 或者删除的是左侧对象
         */
        if (!sourceNode || sourceNode.id == leftObjectUuid) {
          Util.clearEtlJoinLeftObject(targetNode);
        } else {
          Util.clearEtlJoinRightObject(targetNode);
        }
        Util.clearEtlJoinOnList(targetNode);
        break;
      case Constant.ETL_CELL_UNIONALL:
        break;
      case Constant.ETL_CELL_FILTER:
        Util.clearEtlFilterConfig(targetNode);
        break;
      case Constant.ETL_CELL_FIELD_CONFIG:
        break;
      case Constant.ETL_CELL_AGGREGATE:
        break;
      case Constant.ETL_CELL_OUTPUT:
        break;
    }
  }

  /**
   * @description: 节点新增处理
   * @return {void}
   */
  handleNodeAdded() {
    let cell = this.args.cell;
    // 新增节点是输入，则弹出对象选择框
    if (Util.isEtlInputNode(cell)) {
      setTimeout(() => {
        const vm = Util.getVmByCell(this.graph, cell);
        if (vm) {
          vm.openPanel();
          vm.$nextTick(() => {
            vm.showSelector = true;
          });
        }
      }, 0);
    }
  }

  /**
   * @description: 节点删除处理
   * @return {*}
   */
  handleNodeRemoved() {
    let node = this.args.cell;
    let edgeId = node?.model?.outgoings?.[node.id]?.[0];
    let edge = edgeId ? this.graph.getCellById(edgeId) : null;
    let targetNode = edge ? this.graph.getCellById(edge.target.cell) : null;

    console.log(node, edge, targetNode, 'handleNodeRemoved');

    // 删除节点之前会先删除边，直接走删除边逻辑即可
    
    // if (!targetNode) {
    //   return;
    // }

    // /*  横向连接相关变量 - 开始 */
    // let leftObjectUuid = "";
    // /* 横向连接相关变量 - 结束 */

    // switch (targetNode.component) {
    //   case Constant.ETL_CELL_INPUT:
    //     // INPUT节点只能是开始
    //     break;
    //   case Constant.ETL_CELL_JOIN:
    //     leftObjectUuid = targetNode.data.leftObjectUuid;
    //     if (node.id == leftObjectUuid) {
    //       Util.clearEtlJoinLeftObject(targetNode);
    //     } else {
    //       Util.clearEtlJoinRightObject(targetNode);
    //     }
    //     Util.clearEtlJoinOnList(targetNode);
    //     break;
    //   case Constant.ETL_CELL_UNIONALL:
    //     break;
    //   case Constant.ETL_CELL_FILTER:
    //     Util.clearEtlFilterConfig(targetNode);
    //     break;
    //   case Constant.ETL_CELL_FIELD_CONFIG:
    //     break;
    //   case Constant.ETL_CELL_AGGREGATE:
    //     break;
    //   case Constant.ETL_CELL_OUTPUT:
    //     break;
    // }
  }

  /**
   * @description: 单元data改变处理
   * @return {void}
   */
  handleCellChangeData() {
    let cell = this.args.cell;

    if (cell.isNode()) {
      console.log(cell.data.label, cell, 'handleCellChangeData');

      let edgeId = cell?.model?.outgoings?.[cell.id]?.[0];
      let edge = edgeId ? this.graph.getCellById(edgeId) : null;
      let targetNode = edge ? this.graph.getCellById(edge.target.cell) : null;
      let sourceNode = edge ? this.graph.getCellById(edge.source.cell) :null ;

      if (targetNode && sourceNode.component !== Constant.ETL_CELL_FILTER) {
        EtlDataHandler.graph(this.graph, edge).apply();
      }
    }
  }
}
