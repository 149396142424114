/*
 * @Author: ttheitao
 * @Description: 单元工厂
 * @Date: 2022-08-30 10:44:02
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-09-14 15:36:04
 */
import Constant from './Constant';
import ProtGenerater from './PortGenerater';
import Tool from './Tool';

export default class CellFactory {
  /**
   * @description: 创建开始节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createStartNode(graph, options = null) {
    let node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 60,
      height: options?.height ? options.height : 60,
      component: 'Start',
      data: {
        label: options?.label ? options.label : '开始',
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addTop().addRight().addBottom().addLeft().get(),
    };
    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建结束节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEndNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 60,
      height: options?.height ? options.height : 60,
      component: 'End',
      data: {
        label: options?.label ? options.label : '结束',
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addTop().addRight().addBottom().addLeft().get(),
    };
    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建ETL输入节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEtlInputNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 150,
      height: options?.height ? options.height : 36,
      component: Constant.ETL_CELL_INPUT,
      data: {
        label: options?.label ? options.label : Constant.GRAPH_ETL_CELLS_OBJECT[Constant.ETL_CELL_INPUT],
        objectUuid: '', // 对象uuid
        objectFields: [], // 字段uuid集合
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addRight().get(),
    };

    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建ETL输出节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEtlOutputNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 150,
      height: options?.height ? options.height : 36,
      component: Constant.ETL_CELL_OUTPUT,
      data: {
        label: options?.label ? options.label : Constant.GRAPH_ETL_CELLS_OBJECT[Constant.ETL_CELL_OUTPUT],
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addLeft().get(),
    };

    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建ETL多源合并节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEtlUnionAllNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 150,
      height: options?.height ? options.height : 36,
      component: Constant.ETL_CELL_UNIONALL,
      data: {
        label: options?.label ? options.label : Constant.GRAPH_ETL_CELLS_OBJECT[Constant.ETL_CELL_UNIONALL],
        objectFields:[],
        objects:[],
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addRight().get(),
    };

    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建ETL横向连接节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEtlJoinNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 150,
      height: options?.height ? options.height : 36,
      component: Constant.ETL_CELL_JOIN,
      data: {
        label: options?.label ? options.label : Constant.GRAPH_ETL_CELLS_OBJECT[Constant.ETL_CELL_JOIN],
        joinType: Constant.ETL_JOIN_TYPE_LEFT, // 连接方式
        mergeJoinField: true, // 合并连接字段
        leftObjectUuid: '', // 左侧对象uuid
        rightObjectUuid: '', // 右侧对象uuid
        joinOnList: [], // on条件列表
        objectFields: [], // 字段
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addRight().get(),
    };

    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建ETL数据筛选节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEtlFilterNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 150,
      height: options?.height ? options.height : 36,
      component: Constant.ETL_CELL_FILTER,
      data: {
        label: options?.label ? options.label : Constant.GRAPH_ETL_CELLS_OBJECT[Constant.ETL_CELL_FILTER],
        filterType: Constant.ETL_FILTER_TYPE_AND, // 过滤类型
        conditions: [], // 过滤条件
        objectFields: [], // 字段
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addRight().get(),
    };

    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建ETL字段配置节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEtlFieldConfigNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 150,
      height: options?.height ? options.height : 36,
      component: Constant.ETL_CELL_FIELD_CONFIG,
      data: {
        label: options?.label ? options.label : Constant.GRAPH_ETL_CELLS_OBJECT[Constant.ETL_CELL_FIELD_CONFIG],
        objectFields: [],
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addRight().get(),
    };

    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建ETL分组汇总节点
   * @param {Object} graph x6对象
   * @param {Object} options 
   * @return {*}
   */
  static createEtlAggregateNode(graph, options = null) {
    const node = {
      shape: 'vue-shape',
      x: options?.x ? options.x : 0,
      y: options?.y ? options.y : 0,
      width: options?.width ? options.width : 150,
      height: options?.height ? options.height : 36,
      component: Constant.ETL_CELL_AGGREGATE,
      data: {
        label: options?.label ? options.label : Constant.GRAPH_ETL_CELLS_OBJECT[Constant.ETL_CELL_AGGREGATE],
        groupOrOrder: [],// 分组或排序
        objectFields: [], // 字段
      },
      ports: options?.ports ? options.ports : new ProtGenerater().addRight().get(),
    };

    return CellFactory.createNode(graph, node);
  }

  /**
   * @description: 创建节点
   * @param {*} graph
   * @param {*} node
   * @return {*}
   */
  static createNode(graph, node) {
    node.id = 'etl' + Tool.uuid2(10);
    return graph.createNode(node);
  }

  /**
   * @description: 新增执行边
   * @param {*} options
   * @param {*} graph
   * @return {*}
   */
  static addRunEdge(options, graph) {
    return CellFactory.addEdge(options, graph);
  }

  /**
   * @description: 新增条件边
   * @param {*} options
   * @param {*} graph
   * @return {*}
   */
  static addRuleEdge(options, graph) {
    return CellFactory.addEdge(options, graph);
  }

  /**
   * @description: 新增边
   * @param {*} options
   * @param {*} graph
   * @return {*}
   */
  static addEdge(options, graph) {
    options.id = Tool.uuid2(10);
    return graph.addEdge(options);
  }
}