<!--
 * @Author: ttheitao
 * @Description: 工作流节点库
 * @Date: 2022-07-17 16:02:04
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-09-21 17:41:59
-->
<template>
  <div class="stencil-container">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="item"
      :class="{
        enabled: !item.enabled,
      }"
      draggable="true"
      @dragstart="dragstart($event, item)"
    >
      <i :class="item.icon"></i>
      <span class="name">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EtlStencil",
  data() {
    return {
      list: [
        {
          name: "输入",
          type: "EtlInput",
          icon: "iconfont iconshuru",
          iconColor: "#409eff",
          enabled: true,
        },
        {
          name: "横向连接",
          type: "EtlJoin",
          icon: "iconfont iconhengxianglianjie",
          iconColor: "#409eff",
          enabled: true,
        },
        {
          name: "多源合并",
          type: "EtlUnionAll",
          icon: "iconfont iconduoyuanhebing",
          iconColor: "#409eff",
          enabled: true,
        },
        {
          name: "数据筛选",
          type: "EtlFilter",
          icon: "iconfont iconshujushaixuan",
          iconColor: "#409eff",
          enabled: true,
        },
        {
          name: "字段配置",
          type: "EtlFieldConfig",
          icon: "iconfont iconziduanpeizhi",
          iconColor: "#409eff",
          enabled: true,
        },
        {
          name: "分组汇总",
          type: "EtlAggregate",
          icon: "iconfont iconfenzuhuizong",
          iconColor: "#409eff",
          enabled: true,
        },
        {
          name: "输出",
          type: "EtlOutput",
          icon: "iconfont iconshuchu",
          iconColor: "#409eff",
          enabled: true,
        },
      ],
    };
  },
  methods: {
    /**
     * @description: 节点拖拽开始事件
     * @param {*} e 事件
     * @param {*} sencil 节点类型
     * @return {void}
     */
    dragstart(e, sencil) {
      if (!sencil.enabled) {
        return false;
      }

      this.$emit("dragstart", e, sencil);
    },
  },
};
</script>

<style lang="less" scoped>
.stencil-container {
  width: 160px;
  height: 100%;
  // border-right: 1px solid #f1e8e8;
  padding: 10px;
  box-sizing: border-box;
  .item {
    margin-bottom: 15px;
    display: flex;
    width: 100%;
    height: 40px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    background: #f6f7ff;
    border-radius: 5px;
    // box-shadow: #ccc 0px 0px 5px;
    i {
      font-size: 24px;
      color: #409eff;
      padding: 0 10px;
    }
    .name {
      font-size: 14px;
    }

    &:hover {
      cursor: move;
      color: #fff;
      background: #409eff;
      i {
        color: #fff;
      }
    }

    &.enabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
