import { Graph as X6Graph, Addon } from "@antv/x6";
import CustomCells from "./CustomCells";
import EventManager from "./EventManager";
import CellFactory from "./CellFactory";
import Tool from "./Tool.js";
import Constant from "./Constant";
import EtlJoinValidator from "./joinValidator/EtlJoinValidator";

export default class Graph {
  /**
   * @description: 
   * @param {Object} vm vue对象
   * @param {DOM} container DOM
   * @param {Number} type 图形类型
   * @param {Array} cells 图形单元集
   * @return {void}
   */
  constructor(vm, container, type = Constant.GRAPH_TYPE_ETL, cells = []) {
    this.vm = vm;               // vue实例
    this.container = container; // 挂载DOM
    this.type = type;           // 图类型
    this.cells = cells;         // 图单元数据
    this.graph = null;          // 图编辑器实例
    this.dnd = null;            // 拖拽实例

    this.init();
  }

  /**
   * @description: 创建并返回实例
   * @param {Object} vm vue 对象
   * @param {DOM} container 挂载DOM
   * @param {String} type 图类型 
   * @param {*} cells 数据
   * @return {Graph}
   */
  static create(vm, container, type = Constant.GRAPH_TYPE_ETL, cells = []) {
    return new Graph(vm, container, type, cells);
  }

  /**
   * @description: 初始化图编辑器
   * @return {void}
   */
  init() {
    this.graph = new X6Graph(this.getInitOptions());
    // 挂通用信息到 graph 上
    this.graph.__data__ = {
      type: this.type
    }

    const cells = Tool.deepCopy(this.cells);
    CustomCells.register();           // 注册自定义单元到x6
    this.initEventManager();          // 初始化事件管理器
    this.graph.fromJSON(cells);       // 加载单元数据
    this.initDnd();                   // 初始化拖拽实例
  }

  /**
   * @description: 获取初始化选项
   * @return {object}
   */
  getInitOptions() {
    return {
      container: this.container,
      width: undefined,
      height: undefined,
      autoResize: true,
      panning: {
        enabled: true,
        eventTypes: ['rightMouseDown']
      },
      background: {
        color: "#f8f7fc",// 设置画布背景颜色
      },
      // grid: {
      //   size: 10, // 网格大小 10px
      //   visible: true, // 渲染网格背景
      // },
      snapline: false,
      selecting: {
        enabled: true,
        rubberband: true,
        showNodeSelectionBox: true,
        showEdgeSelectionBox: true,
      },
      clipboard: {
        enabled: true,
      },
      keyboard: {
        enabled: true,
      },
      mousewheel: {
        enabled: true,
        factor: 1.02
      },
      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 20,
          },
        },
        connector: {
          name: 'rounded',
          args: {
            radius: 20,
          },
        },
        sourceConnectionPoint: 'anchor',
        allowPort: false,
        allowBlank: false,
        allowMulti: false,
        createEdge: (args) => {
          return this.generateEdgeByNode(args.sourceCell);
        },
        validateEdge: ({ edge }) => {
          const ancestors = this.getAncestorsByEdge(edge);
          const targetCell = this.graph.getCellById(edge.target.cell);
          for (let i in ancestors) {
            if (ancestors[i].id === targetCell.id) {
              this.vm.$message.error('禁止连接当前节点/父节点/祖先节点');
              return false;
            }
          }

          // if (targetCell.shape === 'vue-shape') {
          //   if (targetCell.component === 'Start') {
          //     this.vm.$message.error('禁止连接到开始节点');
          //     return false;
          //   }

          //   if (targetCell.component === 'Condition' && targetCell.parent) {
          //     this.vm.$message.error('禁止连接到条件组中的条件');
          //     return false;
          //   }
          // }

          if (this.type === Constant.GRAPH_TYPE_ETL) {
            // ETL 节点验证
            let validator = EtlJoinValidator.graph(this.graph, edge);
            if (!validator.validate()) {
              this.vm.$message.error(validator.getErrorMsg());
              return false;
            }
          }

          return true;
        }
      },
      embedding: {
        enabled: true,
        validate({ child, parent }) {
          let res = false;

          if (child.shape === 'vue-shape') {
            if (child.component === 'Condition' && parent.component === 'ConditionGroup') {
              return true;
            }
          }

          return res;
        }
      }
    }
  }

  /**
   * @description: 新增执行边
   * @param {*} edge
   * @return {*}
   */
  addRunEdge(edge) {
    return this.addEdge(edge);
  }

  /**
   * @description: 新增边
   * @param {*} edge
   * @return {*}
   */
  addEdge(edge) {
    edge.id = Tool.uuid2(10);
    return this.graph.addEdge(edge);
  }

  /**
   * @description: 根据节点生成对应的边
   * @param {object} sourceNode 源节点
   * @param {object|null} targetNode 目标节点
   * @return {void}
   */
  generateEdgeByNode(sourceNode, targetNode = null) {
    let edge = {
      shape: 'RunEdge',
      source: sourceNode,
      target: targetNode,
      data: {
        priority: 1,
      }
    };

    const priority = Tool.getMaxPriorityByNode(sourceNode, this.graph.getCells()) + 1;
    if (sourceNode.shape === 'Rule'
      || (sourceNode.shape === 'vue-shape' && ['Condition', 'ConditionGroup'].includes(sourceNode.component))) {
      edge.shape = 'RuleEdge';
      edge.attrs = {
        line: {
          stroke: Tool.ruleEdgeStoke.pass,
        },
      };
      edge.labels = [
        {
          attrs: {
            labelText: {
              text: priority,
            }
          }
        }
      ];
      edge.data.priority = priority;
      edge.data.type = 'pass';

      return CellFactory.addRuleEdge(edge, this.graph);
    } else {
      if (this.type === Constant.GRAPH_TYPE_ETL) {
        edge = {
          shape: 'NormalEdge',
          source: sourceNode,
          target: targetNode,
          data: {}
        };
      } else {
        edge.shape = 'RunEdge';
        edge.labels = [
          {
            attrs: {
              labelText: {
                text: priority,
              }
            }
          }
        ];
        edge.data.priority = priority;
      }

      return CellFactory.addRunEdge(edge, this.graph);
    }
  }

  /**
   * @description: 初始化拖拽实例
   * @return {*}
   */
  initDnd() {
    this.dnd = new Addon.Dnd({
      target: this.graph,
      getDragNode: (node) => {
        return node.clone({ keepId: true });
      },
      getDropNode: (node) => {
        return node.clone({ keepId: true });
      },
    });
  }

  /**
   * @description: 处理节点拖拽
   * @param {*} e
   * @param {*} sencil
   * @return {*}
   */
  handleSencilDrag(e, sencil) {
    const node = CellFactory[`create${sencil.type}Node`](this.graph);
    this.dnd.start(node, e);
  }

  /**
   * @description: 删除选中单元
   * @return {*}
   */
  deleteCellsWithSelected() {
    let cells = this.graph.getSelectedCells();
    cells = cells.filter(item => {
      return item.shape !== 'Start';
    });
    if (cells.length) {
      this.graph.removeCells(cells);
    }
  }

  /**
   * @description: 自动修复id，目的是为了生成带单元格类型前缀的uuid
   * @param {array} cells 单元实例数组
   * @return {*}
   */
  autoFixId(cells) {
    cells.forEach(cell => {
      this.graph.updateCellId(cell, `${cell.shape}${Tool.uuid2()}`);
    });
  }

  /**
   * @description: X6事件注册
   * @return {*}
   */
  initEventManager() {
    new EventManager(this);
  }

  /**
     * @description: 通过 edge 获取祖先 node
     * @param {object} edge 
     * @return {array}
     */
  getAncestorsByEdge(edge, hasCurrent = true) {
    const sourceCell = this.graph.getCellById(edge.source.cell);
    // const targetCell = this.graph.getCellById(edge.target.cell);
    const ancestors = hasCurrent ? [sourceCell] : [];
    const cells = this.graph.getCells();

    this.getParentsByCell(sourceCell, cells, ancestors, edge);

    return ancestors;
  }

  /**
   * @description: 通过 cell 获取所有父 node
   * @param {*} cell
   * @param {*} cells
   * @param {*} ancestors
   * @param {*} edge
   * @return {*}
   */
  getParentsByCell(cell, cells, ancestors, edge) {
    if (cell.isEdge()) {
      this.getParentsByCell(this.graph.getCellById(cell.source.cell));
    } else if (cell.isNode()) {
      cells.forEach(item => {
        if (item.isEdge() && item.id !== edge.id && item.target.cell === cell.id) {
          const sourceCell = this.graph.getCellById(item.source.cell);
          ancestors.push(sourceCell);
          this.getParentsByCell(sourceCell, cells, ancestors, edge);
        }
      });
    }
  }
}
