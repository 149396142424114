<!--
 * @Author: ttheitao
 * @Description: 开始
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-24 12:13:09
-->

<template>
  <div class="start-container">
    <div class="content">{{ nodeData.label }}</div>
    <div class="panel">
      <el-drawer
        title="单元配置"
        :visible.sync="showPanel"
        :append-to-body="true"
        :modal="false"
        size="400px"
      >
        <el-collapse v-model="activeNames" class="graph-cell-panel">
          <el-collapse-item title="基本设置" name="1">
            <div class="item">
              <div class="label">名称</div>
              <div class="main">
                <el-input v-model="nodeData.label" size="small"></el-input>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import BaseOptions from './mixins/BaseOptions.js';

export default {
  name: 'End',
  mixins: [BaseOptions],
  data() {
    return {
      activeNames: ['1', '2'],
    };
  },
};
</script>

<style lang="less" scoped>
.start-container {
  width: 100%;
  height: 100%;
  border: 2px solid #409eff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
}
</style>
