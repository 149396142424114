<!--
 * @Author: ttheitao
 * @Description: 输出
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-16 18:05:02
-->

<template>
  <div class="etl-cell-container">
    <div class="content">
      <span><i class="iconfont iconshuchu"></i></span>
      {{ nodeData.label }}
    </div>
    <el-drawer
      class="etl-cell-panel etl-cell-output-panel"
      :visible.sync="showPanel"
      :append-to-body="panel.modalAppendToBody"
      :modal="panel.modal"
      :direction="panel.direction"
      :size="panel.size"
      :show-close="panel.showClose"
      @close="onDrawerClose"
    >
      <template slot="title">
        <div class="type">
          <i class="iconfont iconshuchu"></i>
          输出
        </div>
        <div class="name">节点名称：{{ nodeData.label }}</div>
      </template>
      <div class="main">
        <DataPreview :nodeId="node.id" ref="preview"></DataPreview>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import BaseOptions from './mixins/BaseOptions.js';
import DataPreview from '../components/DataPreview.vue';

export default {
  name: 'EtlOutput',
  mixins: [BaseOptions],
  components:{DataPreview},
  data() {
    return {
      tableData: []
    };
  },
  watch:{
    showPanel(val){
      if(val){
        this.$nextTick(() => {
          this.$refs.preview.reload();
        })
      }
    }
  }
};
</script>

<style lang="less">
.etl-cell-output-panel {
  .dataList {
    flex: 1;
    overflow: auto;
  }
}
</style>
