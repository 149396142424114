<!--
 * @Author: ttheitao
 * @Description: 分组汇总
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-16 17:53:17
-->

<template>
  <div class="etl-cell-container">
    <div class="content">
      <i class="iconfont iconfenzuhuizong"></i>
      {{ nodeData.label }}
    </div>
    <el-drawer
      class="etl-cell-panel etl-cell-aggregate-panel"
      :visible.sync="showPanel"
      :append-to-body="panel.modalAppendToBody"
      :modal="panel.modal"
      :direction="panel.direction"
      :size="panel.size"
      :show-close="panel.showClose"
      @close="onDrawerClose"
    >
      <template slot="title">
        <div class="type">
          <i class="iconfont iconfenzuhuizong"></i>
          分组汇总
        </div>
        <div class="name">
          <span>节点名称：</span>
          <el-input v-model="nodeData.label"></el-input>
        </div>
      </template>
      <div class="main">
        <div class="group-fields">
          <div class="title">分组排序字段</div>
          <div class="add-field-btn" @click="addGroupOrOrderField">
            <i class="iconfont iconxinzeng2"></i> 添加分组排序字段
          </div>
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in nodeData.groupOrOrder"
              :key="index"
            >
              <el-select
                class="field-select"
                v-model="item.code"
                placeholder="请选择字段"
              >
                <el-option
                  v-for="(item, index) in nodeData.objectFields"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                  :disabled="goCodes.indexOf(item.code) > -1"
                >
                </el-option>
              </el-select>
              <el-select
                class="type-select"
                v-model="item.type"
                placeholder="请选择操作类型"
              >
                <el-option
                  v-for="(item, index) in goTypes"
                  :key="index"
                  :label="item.name"
                  :value="item.type"
                >
                </el-option>
              </el-select>
              <span class="del" @click="delGroupOrOrderField(index, item)">
                <i class="iconfont iconc-trash-can"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="aggregate-fields">
          <div class="title">汇总字段</div>
          <div class="add-field-btn" @click="addAggregateField">
            <i class="iconfont iconxinzeng2"></i>
            添加汇总字段
          </div>
          <div class="list">
            <template v-for="(item, index) in nodeData.objectFields">
              <div class="item" :key="index" v-if="item.aggregate">
                <el-select
                  v-if="!item.origin"
                  class="field-select"
                  placeholder="请选择字段"
                  :value="item.origin"
                  @change="aggregateFieldChange($event, index)"
                >
                  <template v-for="(item2, index2) in nodeData.objectFields">
                    <el-option
                      v-if="!item2.aggregate"
                      :key="index2"
                      :label="item2.name"
                      :value="item2.code"
                    >
                    </el-option>
                  </template>
                </el-select>
                <!-- <span class="edit-alias-btn" @click="editAlias(item, index)">
                  <i class="el-icon-edit"></i>
                </span> -->
                <el-input
                  v-else
                  v-model="item.name"
                  style="width: 200px"
                  placeholder="字段别名"
                ></el-input>
                <el-select
                  class="type-select"
                  v-model="item.aggregate"
                  placeholder="请选择汇总类型"
                >
                  <el-option
                    v-for="(item3, index3) in allAggregates"
                    :key="index3"
                    :label="item3.name"
                    :value="item3.type"
                  >
                  </el-option>
                </el-select>
                <span class="del" @click="delAggregateField(index, item)">
                  <i class="iconfont iconc-trash-can"></i>
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import BaseOptions from "./mixins/BaseOptions.js";
import collect from "collect.js";
import Constant from "../libs/Constant";
import Tool from "../libs/Tool.js";

export default {
  name: "EtlAggregate",
  mixins: [BaseOptions],
  components: {},
  data() {
    return {
      tableData: [],
      editIndex: null,
    };
  },
  computed: {
    allAggregates: function () {
      return Constant.ETL_AGGREGATES;
    },
    goTypes: function () {
      return Constant.ETL_GO_TYPES;
    },
    goCodes: function () {
      return collect(this.nodeData.groupOrOrder).pluck("code").all();
    },
  },
  methods: {
    /**
     * @description:新增分组排序字段
     * @return {void}
     */
    addGroupOrOrderField() {
      this.nodeData.groupOrOrder.push({
        type: Constant.ETL_GO_ASC,
        code: "",
      });
    },
    /**
     * @description: 删除分组字段
     * @param {Number} index 索引
     * @return {void}
     */
    delGroupOrOrderField(index) {
      this.nodeData.groupOrOrder.splice(index, 1);
    },
    /**
     * @description: 新增汇总字段
     * @return {*}
     */
    addAggregateField() {
      this.nodeData.objectFields.push({
        origin: "", // 原始code
        type: "", // 字段类型
        aggregate: Constant.ETL_AGGREGATE_SUM, // 聚合类型
        code: `f${Tool.uuid2(9)}`, // code
        name: "", // 名称
      });
    },
    /**
     * @description: 删除汇总字段
     * @param {Number} index
     * @return {void}
     */
    delAggregateField(index) {
      this.nodeData.objectFields.splice(index, 1);
    },
    /**
     * @description: 聚合字段修改
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    aggregateFieldChange(code, aggregateIndex) {
      let oField = this.nodeData.objectFields.filter((item) => {
        return item.code === code;
      })[0];
      let aField = this.nodeData.objectFields[aggregateIndex];
      aField.origin = oField.code;
      aField.type = oField.type;
      aField.name = oField.name;
    },
  },
};
</script>

<style lang="less">
.etl-cell-aggregate-panel {
  .main {
    .group-fields {
      flex: 1;
      padding: 15px;
      overflow: auto;
      border-right: 1px solid #e9e9e9;
    }

    .aggregate-fields {
      flex: 1;
      padding: 15px;
    }

    .group-fields,
    .aggregate-fields {
      .list {
        margin-top: 15px;
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;

          .edit-alias-btn {
            margin: 0 5px;
          }
          .del {
            margin-left: 5px;
          }
          .el-select,
          .el-input {
            margin-right: 5px;
          }

          .field-select {
            width: 200px;
          }

          .type-select {
            width: 100px;
          }
        }
      }
    }

    .add-field-btn {
      color: #409eff;
      width: 150px;
    }
  }
}
</style>
