<!--
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @Description: 新版字段管理列表
 * @FilePath: /dataview-next/src/views/objects/fieldsManage.vue
 * @Date: 2022-08-30 15:07:56
 * @LastEditTime: 2023-01-04 09:53:54
 * @Author: lisushuang
-->
<template>
  <div class="fields-manage">
    <!-- no object_uuid -->
    <section v-if="!objectUuid">
      <Empty description="无效的对象" />
      <Button @click="$router.back()" v-if="showBack">返回上一级</Button>
    </section>

    <!-- the main fields manager -->
    <section v-else class="main-manager">
      <article>
        <!-- header titie and refresh button  -->
        <header>
          <section>
            <!-- 是否需要返回按钮？ -->
            <!-- <Button
              @click="$router.back()"
              v-if="showBack"
              type="primary"
              icon="el-icon-arrow-left"
            ></Button> -->
            <h1>{{ objectShowName }}</h1>
            <Button @click="onRefresh" :disabled="tableLoading"
              >刷新数据</Button
            >
            <Button @click="onAddField" type="primary">添加字段</Button>
            <Button @click="hideSysFields = !hideSysFields" type="danger"
              >{{ hideSysFields ? "显示" : "隐藏" }}系统字段</Button
            >
            <div v-if="objectName" style="display: inline-block;margin-left: 36px;">
              <el-button-group >
                <template v-for="(item, index) in btns">
                  <el-button
                  :key="index"
                  size="mini"
                  type="warning"
                  v-if="item.need"
                  @click="createDefaultField(item.type, item.desc)"
                  >{{ item.desc }}</el-button
                >
                </template>
              </el-button-group>
            </div>
          </section>

          <section class="object-info">
            <Col :span="12">
              <Input disabled v-model="objectCode">
                <template slot="prepend">数据源code</template>
              </Input>
            </Col>
            <Col :span="12">
              <Input v-model="objectName">
                <template slot="prepend">数据源名称</template>
              </Input>
            </Col>
          </section>
        </header>
        <!-- the main table to manage fields -->
        <main>
          <Table
            v-loading="tableLoading"
            :data="showFields"
            class="table"
            size="small"
            border
            stripe
            height="98%"
          >
            <TableColumn label="序号" align="center" width="50">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </TableColumn>
            <TableColumn
              label="唯一id"
              prop="uuid"
              align="center"
              width="140"
              v-if="!hideSysFields"
            />
            <TableColumn
              label="字段uuid"
              prop="uuid"
              align="center"
              width="200"
           />
            <TableColumn
              label="字段code"
              prop="code"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                {{ scope.row.code }}
              </template>
            </TableColumn>
            <TableColumn
              label="字段名称"
              prop="name"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <Input :disabled="updateFieldLoading" size="mini" v-model="scope.row.name" @blur="onRowChange(scope.row.uuid,'name',scope.row)" />
              </template>
            </TableColumn>
            <TableColumn
              label="字段类型"
              props="type"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <Tag v-if="scope.row.type !== 'append'">{{
                  showFieldType(scope.row.type)
                }}</Tag>

                <!-- 虚拟字段按钮 -->
                <Tag v-else effect="dark" type="warning" @click.stop="onOpenValueForm(scope.row.uuid)">{{
                  showFieldType(scope.row.type)
                }}</Tag>
              </template>
            </TableColumn>
            <TableColumn
              label="默认值"
              prop="default"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <Input :disabled="updateFieldLoading" size="mini" v-model="scope.row.default" @blur="onRowChange(scope.row.uuid,'default',scope.row)" />
              </template>
            </TableColumn>
            <TableColumn
              label="字段备注"
              prop="comment"
              align="center"
              min-width="200"
            >
              <template slot-scope="scope">
                <Input :disabled="updateFieldLoading" size="mini" v-model="scope.row.comment" @blur="onRowChange(scope.row.uuid,'comment',scope.row)"/>
              </template>
            </TableColumn>
            <TableColumn
              label="创建时间"
              prop="created_at"
              align="center"
              width="150"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.created_at || "" }}
              </template>
            </TableColumn>

            <!-- <el-table-column
              align="center"
              label="操作"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" @click="onAddField(row)">编辑</el-button>
              </template>
            </el-table-column> -->
          </Table>
        </main>
      </article>
    </section>

    <!-- 虚拟字段弹窗 -->
    <el-dialog
      class="etl-field-dialog"
      :title="
        fieldUuid === null ? '新增计算字段' : '修改计算字段：' + fieldName
      "
      v-if="showValueBase"
      :visible.sync="showValueBase"
      :append-to-body="true"
      width="800px"
      v-loading="fieldLoading"
    >
      <ValueForm
        v-model="fieldExtra"
        realTime
        :obuuid="obuuid"
        :formData="fieldExtra"
        :needTitle="false"
        class="value-form"
        :fieldList="fieldList"
      ></ValueForm>
      <span slot="footer" class="dialog-footer">
        <!-- <el-input
          v-model="fieldName"
          placeholder="计算字段名称"
          class="field-name"
        ></el-input> -->
        <el-button @click="onSaveFormula" type="primary">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增字段弹窗 -->
    <!-- 没有采用行内编辑的形式，感觉上新增的时候没有反馈 -->
    <el-dialog
      class="etl-field-dialog"
      title="新增字段"
      v-if="showAddForm"
      :visible.sync="showAddForm"
      :append-to-body="true"
      width="400px"
      v-loading="fieldLoading"
    >
      <el-form
        label-width="100px"
        ref="addForm"
        label-position="top"
        :rules="addFormRules"
        :model="addForm"
      >
        <el-form-item label="字段名称" prop="fieldName">
          <el-input v-model="addForm.fieldName"></el-input>
        </el-form-item>
        <el-form-item label="字段code" prop="fieldCode">
          <el-input v-model="addForm.fieldCode"></el-input>
        </el-form-item>
        <el-form-item label="字段类型" prop="fieldType">
          <el-select v-model="addForm.fieldType" class="add-field-type">
            <el-option
              v-for="item in fieldTypes"
              :label="item.name"
              :value="item.value"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onSaveField" type="primary" class="footer-button"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script >
import {
  Empty,
  Button,
  Table,
  TableColumn,
  Tag,
  Input,
  Col,
  Message,
  ButtonGroup
} from "element-ui";
import objects from "../../api/objects";
import ValueForm from "./components/valueForm.vue";

// 系统字段保留code
const systemFieldCodes = [
  "table",
  "module",
  "user_id",
  "user_name",
  "user_table",
  "archi_id",
  "archi_table",
  "archi_type",
  "created_at",
  "updated_at",
  "deleted_at",
  "id",
  "flow_engine_id",
  "flow_engine_status",
  "user_department_id",
  "object_uuid",
  "view_uuid",
  "relationship_uuid",
  "search",
  "data_id",
  "__method_name__",
  "page",
  "size",
  "per_page",
  "form_uuid",
  "dictionary_uuid",
  "event_uuid",
  "behavior_uuid",
  "ruuids",
  "relationship_uuid",
  "transcode",
  "__object_uuid__",
  "__object_name__",
  "__form_uuid__",
  "__view_uuid__",
  "__aggregate_id__",
];

export default {
  name: "fieldsManage",
  components: { Empty, Button, Table, TableColumn, Tag, Input, Col,ValueForm,'el-button-group': ButtonGroup,},
  computed: {
    objectUuid() {
      if (!this.$route) return this.obuuid;
      return (
        this.$route.query.object_uuid || this.obuuid || this.$route.query.obuuid
      );
    },
    objectShowName() {
      return this.objectName || "数据加载中...";
    },
    showFields() {
      if (!this.hideSysFields) {
        return this.fields;
      }
      let tmp = this.fields.filter((item) => {
        return item.uuid && systemFieldCodes.indexOf(item.code) === -1;
      });
      return tmp;
    },

    fieldList() {
      return this.fields.filter((item) => {
        return item.uuid && item.uuid !== "";
      });
    },
    btns(){
      return [
        { type: "user", desc: "用户" ,need:this.needShowAddBtn('user')},
        { type: "archi", desc: "架构" ,need:this.needShowAddBtn('archi')},
        { type: "flow", desc: "流程" ,need:this.needShowAddBtn('flow')},
      ]
    },
  },
  props: {
    obuuid: {
      type: String,
      default: "",
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      objectName: "",
      objectCode: "",
      objectEnabled:"",
      fields: [],
      tableLoading: false,
      editRow: 0,
      hideSysFields: true,

      fieldTypes: objects.fieldTypes.filter((item) => item.value !== 'string'),

      showAddForm: false,

      showValueBase: false,
      fieldIndex: null,
      fieldName: "",
      fieldCode: "",
      fieldType: "",
      fieldExtra: "",
      fieldDefault: "",
      fieldUuid: "",
      fieldLoading : false,

      addForm: {
        fieldName: "",
        fieldType: "",
        fieldCode: "",
      },

      addFormRules: {
        fieldName: [
          { required: true, message: "请输入字段名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "code的长度在1到30个字符之间",
            trigger: "blur",
          },
        ],
        fieldCode: [
          { required: true, message: "请输入字段code", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "code的长度在1到30个字符之间",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9_]+$/,
            message: "code只能是字母和数字以及下划线",
          },
        ],
        fieldType: [
          { required: true, message: "请选择字段类型", trigger: "change" },
        ],
      },
      updateFieldLoading: false
    };
  },

  methods: {
        /**
     * @description: 根据字段是否存在返回是否需要展示某个按钮
     * @param {*} type <String> 字段code 或者 补充类型type
     * @return {*}
     */
     needShowAddBtn(type){
      if(type == 'user'){
        return !(this.needShowAddBtn('user_id') && this.needShowAddBtn('user_table') && this.needShowAddBtn('user_department_id'))
      }
      if(type == 'archi'){
        return !(this.needShowAddBtn('archi_id') && this.needShowAddBtn('archi_table') && this.needShowAddBtn('archi_type'))
      }
      if(type == 'flow'){
        return !(this.needShowAddBtn('flow_engine_id') && this.needShowAddBtn('flow_engine_status'))
      }
      for(let i = 0;i<this.fieldList.length;i ++){
        if(this.fieldList[i].code == type){
          return true
        }
      }
      return false
    },
    /**
     * @description: 创建默认的字段
     * @param {*} type String 创建的类型 archi user flow
     * @param {*} desc String 创建的描述 架构 用户 流程
     * @return {*}
     */
    createDefaultField(type, desc) {
      this.$confirm("确认补充" + desc + "的字段吗？", "提示")
        .then((res) => {
          if (res == "confirm") {
            objects
              .addDefaultField(this.obuuid, type)
              .then(() => {
                this.$message.success("操作成功！");
                this.$emit('getData');
              })
              .catch(() => {
                this.$message.error("操作失败！");
              });
          }
        })
        .catch((e) => {console.log(e)});
    },
    /**
     * @description: 点击刷新按钮
     */
    onRefresh() {
      this.objectName = "";
      this.objectCode = "";
      this.objectEnabled = "";
      this.fields = [];
      this.handleObjectInfo();
    },

    /**
     * @description: 获取字段类型的中文名
     * @param {String} type 字段类型
     * @return {String} 字段类型中文名
     */
    showFieldType(type) {
      const one = objects.fieldTypes.filter((item) => {
        return item.value == type;
      });
      if (one.length) return one[0].name.split(" - ")[0];
      return type;
    },

    /**
     * @description: 获取对象详情
     */
    handleObjectInfo() {
      if (!this.objectUuid) return;
      this.tableLoading = true;
      objects
        .getOne(this.objectUuid)
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code === 200) {
            const data = res.data.data;
            this.objectName = data.name;
            this.objectCode = data.code;
            this.fields = data.fields;
            this.objectEnabled = data.enabled;
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    /**
     * @description: 新增字段或者修改字段
     * @param {Object|null} field 字段数据对象
     */
    onAddField() {
      this.fieldCode = "";
      this.fieldType = "";
      this.fieldName = "";
      this.showAddForm = true;
    },

    /**
     * @description: 保存新增字段
     */
    onSaveField() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if(systemFieldCodes.indexOf(this.fieldCode) > -1){
            Message.error("字段code不能为系统保留名称！");
            return ;
          }
          if (this.fieldLoading) {
            return
          }
          this.fieldLoading = true;
          objects.addField({code:this.addForm.fieldCode,name:this.addForm.fieldName,type:this.addForm.fieldType,object_uuid: this.objectUuid}).then(res => {
            this.fieldLoading = false;
            if(res.data.code === 200){
              this.addForm = {
                fieldName:"",
                fieldCode:"",
                fieldType:"",
              }
              this.showAddForm = false;
              this.onRefresh()
            }
          }).catch(() => {
            this.fieldLoading = false;
          })
        } else {
          return false;
        }
      });
    },
    /**
     * @description: 打开
     * @param {Number} uuid 字段在objectFields里面的序号
     */
    onOpenValueForm(uuid = null) {
      if(!uuid) return ;
      this.fieldUuid = uuid;
      const field = this.fields.filter(item => item.uuid == uuid)[0];
      this.fieldName = field.name;
      this.fieldExtra = JSON.parse(JSON.stringify(field.extra));
      this.showValueBase = true;
    },

    /**
     * @description: 保存虚拟字段配置
     */
    onSaveFormula() {
      // 保存虚拟字段
      // Message.info("nothing");
      this.fieldLoading = true;
      objects.editField(this.fieldUuid,{extra:this.fieldExtra,name:this.fieldName}).then(res => {
        this.fieldLoading = false;
        if(res.data.code === 200){
          this.showValueBase = false;
          this.fieldUuid = null;
          this.fieldExtra = null;
        }
        this.handleObjectInfo()
      }).catch(() => {
        this.fieldLoading = false;
      })
    },

    /**
     * @description: 行内编辑 用于行内blur事件
     * @param {String} uuid 字段uuid
     * @param {String} name 字段本身的属性code
     * @param {String} value 修改的值
     */
    onRowChange(uuid, name, row) {
      if (uuid && name && row) {
        if (this.updateFieldLoading) {
          return this,this.$message.warning('请等待上一次修改成功后修改！')
        }
        this.updateFieldLoading = true
        objects.editField(uuid,{name: row.name, default: row.default, comment: row.comment }).then(() => {
          this.updateFieldLoading = false
          this,this.$message.success('修改成功！')
        }).catch(() => {
          this.updateFieldLoading = false
          this.onRefresh()
          this,this.$message.error('修改失败！')
        })
      }

    },
  },

  mounted() {
    this.handleObjectInfo();
  },
};
</script>

<style lang="less" scoped>

@import "../../manage-views/css/manageAdd.less";
.fields-manage {
  width: 100%;
  padding: 10px;
}
.main-manager {
  height: 100%;
  header {
    text-align: left;
    h1 {
      font-size: 18px;
      display: inline;
      margin-right: 10px;
      margin-left: 10px;
      line-height: 30px;
    }
    .object-info {
      padding-top: 10px;
      :nth-child(1) {
        padding-right: 10px;
      }
      :nth-child(2) {
        padding-left: 10px;
      }
    }
  }
  article {
    height: 100%;
    display: flex;
    flex-flow: column;
    main {
      flex-grow: 1;
      padding-top: 10px;
    }
  }
}
.etl-field-dialog {
  .el-dialog {
    margin-top: 0 !important;
  }
  .add-field-type {
    width: 100%;
  }
  .dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .footer-button {
      width: 100px;
    }
  }
}
</style>

