/*
 * @Author: ttheitao
 * @Description: ETL数据处理器
 * @Date: 2022-09-06 16:00:50
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-19 10:40:10
 */

import collect from "collect.js";
import Constant from "../Constant";
import Tool from "../Tool";
import Util from "../Util";

export default class EtlDataHandler {
  /**
   * @description: 构造函数
   * @param {*} graph x6
   * @param {*} edge 边
   * @return {*}
   */
  constructor(graph, edge) {
    this.graph = graph;
    this.edge = edge;
    this.sourceCell = this.graph.getCellById(edge.source.cell);
    this.targetCell = this.graph.getCellById(edge.target.cell);
  }


  /**
   * @description: 创建
   * @param {*} graph x6
   * @param {*} edge 边
   * @return {*}
   */
  static graph(graph, edge) {
    return new EtlDataHandler(graph, edge);
  }

  /**
   * @description: 应用
   * @return {boolean}
   */
  apply() {
    switch (this.targetCell.component) {
      case Constant.ETL_CELL_INPUT:
        break;
      case Constant.ETL_CELL_JOIN:
        this.handleJoin();
        break;
      case Constant.ETL_CELL_UNIONALL:
        this.handleUnionAll();
        break;
      case Constant.ETL_CELL_FILTER:
        this.handleFilter();
        break;
      case Constant.ETL_CELL_FIELD_CONFIG:
        this.handleFieldConfig();
        break;
      case Constant.ETL_CELL_AGGREGATE:
        this.handleAggregate();
        break;
      case Constant.ETL_CELL_OUTPUT:
        this.handleOutput();
        break;
    }
  }

  /**
   * @description: 处理横向连接节点
   * @return {void}
   */
  handleJoin() {
    let leftObjectUuid = this.targetCell.data.leftObjectUuid;
    let rightObjectUuid = this.targetCell.data.rightObjectUuid;
    let objectFields = Tool.deepCopy(this.sourceCell.data.objectFields);
    let isJoinLeft = this.sourceCell.id === leftObjectUuid;
    let isJoinRight = this.sourceCell.id === rightObjectUuid;
    let updateData = {
      leftObjectUuid,
      rightObjectUuid,
      objectFields: [],
      joinOnList: [],
    };

    /**
     * 匹配到左侧则重置左侧对象
     * 匹配到右侧则重置右侧对象
     * 没有匹配到说明是新增，则顺序占坑
     */
    if (isJoinLeft) {
      Util.clearEtlJoinLeftObject(this.targetCell);
    } else if (isJoinRight) {
      Util.clearEtlJoinRightObject(this.targetCell);
    } else {
      if (!leftObjectUuid) {
        updateData.leftObjectUuid = this.sourceCell.id;
      } else {
        updateData.rightObjectUuid = this.sourceCell.id;
      }
    }

    for (let i in objectFields) {
      let item = objectFields[i];
      let temp = {
        origin: `${this.sourceCell.id}.${item.code}`,
        code: `f${Tool.uuid2(9)}`,
        name: item.name,
        type: item.type,
      };
      updateData.objectFields.push(temp);
    }
    updateData.objectFields = updateData.objectFields.concat(this.targetCell.data.objectFields);
    this.targetCell.updateData(updateData);
  }

  /**
   * @description: 处理数据筛选节点
   * @return {void}
   */
  handleFilter() {
    this.targetCell.updateData({ 'objectFields': Util.generateObjectFields(this.sourceCell), 'conditions': [] });
  }

  /**
   * @description: 处理数据筛选节点
   * @return {void}
   */
  handleAggregate() {
    this.targetCell.updateData({ groupOrOrder: [], objectFields: Util.generateObjectFields(this.sourceCell) });
  }

  /**
   * @description: 处理输出节点 
   * @return {void}
   */
  handleOutput() {
    this.targetCell.updateData({ objectFields: Util.generateObjectFields(this.sourceCell) });
  }

  /**
   * @description: 处理多源合并节点 
   * @return {void}
   */
  handleUnionAll() {
    /** data 
     * {
     *  origin:"id.code",
     *  code:uniqid(),
     *  name:"",
     *  type:""
     *  maps:[
     *   id:code,
     *    id2:code,
     * ]
     * }
     */
    let objects = [];
    let objectFields = [];
    // 找到当前节点的上级节点
    let sources = this.sourceCell.model?.incomings?.[this.targetCell.id]
    // 无上级，清空
    if (!sources || !sources.length) {
      this.targetCell.updateData({ objectFields: [], objects: [] });
      // this.targetCell.data.objectFields = [];
      // this.targetCell.data.objects = [];
      return;
    }


    // 本节点无配置时，用第一个来源节点的objectFields初始化当前节点
    for (let i = 0; i < sources.length; i++) {
      const element = sources[i];
      const itemEdge = this.graph.getCellById(element);
      const item = this.graph.getCellById(itemEdge.source.cell)
      if (item?.data?.objectFields.length && !this.targetCell?.data?.objectFields.length) {
        // 初始化 本节点的 objectFields;
        objectFields = JSON.parse(JSON.stringify(item.data.objectFields));
        objectFields.forEach((field) => {
          field.origin = '';
          field.maps = {
            [item.id]: JSON.parse(JSON.stringify(field)).code
          }
          field.code = `f${Tool.uuid2(9)}`;
        })
      }
      objects.push({ id: item.id, name: item.data.label });
      let sourceFields = collect(item.data.objectFields).pluck('code').all();

      let count = 0;

      // 处理字段删除，及空的新增
      objectFields.forEach((field) => {
        if (field.maps[item.id]) {
          count++;
          // 如果不在原始字段列表内，那么置空
          if (sourceFields.indexOf(field.maps[item.id]) == -1) {
            field.maps[item.id] = '';
          }
        } else {
          field.maps[item.id] = '';
        }
      })

      // 如果一个对应关系都没有，那么自动对应一个
      if (count === 0) {
        objectFields.forEach((field) => {
          for (let i = 0; i < item.data.objectFields.length; i++) {
            const element = item.data.objectFields[i];
            if (element.name == field.name) {
              field.maps[item.id] = element.code;
            }
          }
        })
      }
    }

    this.targetCell.updateData({ objectFields, objects });
    // this.targetCell.data.objects = objects;
    console.log(this.targetCell.data, 'this.targetcell.data')
  }

  /**
   * @description: 处理字段配置节点
   * @return {void}
   */
  handleFieldConfig() {
    let objectFields = Util.generateObjectFields(this.sourceCell);
    for (let i in objectFields) {
      objectFields[i].show = true;
      objectFields[i].fieldType = Constant.ETL_FIELD_REAL;
    }
    this.targetCell.updateData({ objectFields });
  }
}