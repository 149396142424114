/*
 * @Author: ttheitao
 * @Description: ETL节点baseMixin
 * @Date: 2022-07-05 09:28:49
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-09 13:57:33
 */
import Tool from "../../libs/Tool";

export default {
  inject: ["getGraph", "getNode"],
  data() {
    return {
      hasPanel: true,  // 当前组件是否包含配置面板
      showPanel: false,  // 是否显示面板
      isMoving: false,  // 当前节点是否在移动状态
      nodeData: null,   // 节点业务数据
      panel: {// 面板相关
        size: '400px', // Drawer 窗体的大小
        direction: 'btt', // 面板弹出方式
        modal: false, //是否需要遮罩层
        modalAppendToBody: true, // 遮罩层是否插入至 body 元素上
        showClose: false, // 是否显示关闭按钮
      }
    }
  },
  watch: {
    nodeData: {
      handler(newVal) {
        const node = this.getNode();
        node.replaceData(Tool.deepCopy(newVal));
      },
      deep: true
    }
  },
  created() {
    this.initNodeData();
  },
  computed: {
    graph() {
      return this.getGraph();
    },
    node() {
      return this.getNode();
    }
  },
  methods: {
    initNodeData() {
      this.nodeData = Tool.deepCopy(this.node.data);
    },
    openPanel() {
      console.log('openPanel');
      this.initNodeData();
      this.showPanel = true;
    },
    closePanel() {
      console.log('closePanel');
      this.showPanel = false;
    },
    onDrawerClose() {
      // 关闭面板时清空选区
      this.graph.unselect(this.node);
    },
    setMovingStatus(status = true) {
      console.log('setMovingStatus', status);
      this.isMoving = status;
    }
  }
}