<!--
 * @Author: ttheitao
 * @Description: 横向连接
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-19 10:03:35
-->

<template>
  <div class="etl-cell-container">
    <div class="content">
      <i class="iconfont iconhengxianglianjie"></i>
      {{ nodeData.label }}
    </div>
    <el-drawer
      class="etl-cell-panel etl-join-panel"
      :visible.sync="showPanel"
      :append-to-body="panel.modalAppendToBody"
      :modal="panel.modal"
      :direction="panel.direction"
      :size="panel.size"
      :show-close="panel.showClose"
      @close="onDrawerClose"
    >
      <template slot="title">
        <div class="type">
          <i class="iconfont iconhengxianglianjie"></i>
          横向连接
        </div>
        <div class="name">
          <span>节点名称：</span>
          <el-input v-model="nodeData.label"></el-input>
        </div>
      </template>
      <div class="main">
        <div class="m-left join-type">
          <div class="title">1. 设置连接方式</div>
          <el-radio-group v-model="nodeData.joinType" size="small">
            <el-radio-button
              v-for="(item, index) in joinTypes"
              :key="index"
              :label="item.type"
            >
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
          <div class="title">连接字段设置</div>
          <el-checkbox v-model="nodeData.mergeJoinField">
            合并连接字段
          </el-checkbox>
        </div>
        <div class="m-right join-fields">
          <div class="title">2. 添加连接字段</div>
          <div class="object-info">
            <div class="left-object">
              左侧表单：<i class="iconfont icona-wendangjilu"></i>
              {{ leftObjectName }}
            </div>
            <div class="empty1"></div>
            <div class="right-object">
              右侧表单：<i class="iconfont icona-wendangjilu"></i>
              {{ rightObjectName }}
            </div>
            <span class="empty2"></span>
          </div>
          <div class="join-on-list">
            <div
              class="item"
              v-for="(item, key) in nodeData.joinOnList"
              :key="key"
            >
              <div class="left-input">
                <el-select size="small" v-model="item.left">
                  <el-option
                    v-for="(item, index) in leftObjectFields"
                    :key="index"
                    :value="item.code"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </div>
              <span class="operator">=</span>
              <div class="right-input">
                <el-select size="small" v-model="item.right">
                  <el-option
                    v-for="(item, index) in rightObjectFields"
                    :key="index"
                    :value="item.code"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </div>
              <span class="del" @click="delJoinOn(key, item)">
                <i class="iconfont iconc-trash-can"></i>
              </span>
            </div>
          </div>
          <div class="primary-btn" @click="addJoinOn">
            <i class="iconfont iconxinzeng2"></i> 添加
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import BaseOptions from "./mixins/BaseOptions.js";
import { RadioGroup, RadioButton } from "element-ui";
import Constant from "../libs/Constant";

export default {
  name: "EtlJoin",
  mixins: [BaseOptions],
  components: {
    "el-radio-group": RadioGroup,
    "el-radio-button": RadioButton,
  },
  data() {
    return {};
  },
  computed: {
    leftObjectName: function () {
      if (!this.nodeData.leftObjectUuid) {
        return "";
      }

      let cell = this.graph.getCellById(this.nodeData.leftObjectUuid);
      return cell ? cell.data.label : "";
    },
    rightObjectName: function () {
      if (!this.nodeData.rightObjectUuid) {
        return "";
      }

      let cell = this.graph.getCellById(this.nodeData.rightObjectUuid);
      return cell ? cell.data.label : "";
    },
    joinTypes: function () {
      return Constant.ETL_JOIN_TYPES;
    },
    leftObjectFields: function () {
      if (!this.nodeData.leftObjectUuid) return [];
      return this.nodeData.objectFields.filter((item) => {
        return item.origin.includes(this.nodeData.leftObjectUuid);
      });
    },
    rightObjectFields: function () {
      if (!this.nodeData.rightObjectUuid) {
        return [];
      }

      return this.nodeData.objectFields.filter((item) => {
        return item.origin.includes(this.nodeData.rightObjectUuid);
      });
    },
  },
  methods: {
    /**
     * @description: 添加连接条件
     * @return {void}
     */
    addJoinOn() {
      this.nodeData.joinOnList.push({
        left: "",
        operator: Constant.ETL_OPERATOR_EQ,
        right: "",
      });
    },
    /**
     * @description: 删除连接条件
     * @param {Number} key 索引
     * @return {void}
     */
    delJoinOn(key) {
      this.nodeData.joinOnList.splice(key, 1);
    },
  },
};
</script>

<style lang="less">
.etl-join-panel {
  .main {
    .join-type {
      padding: 15px;
      .el-radio-group {
        margin-bottom: 15px;
      }
    }
    .join-fields {
      padding: 15px;

      .object-info {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        .left-object,
        .right-object {
          flex: 1;
          text-align: left;
          min-width: 260px;
        }

        .empty1 {
          width: 50px;
        }
        .empty2 {
          width: 20px;
        }
      }

      .join-on-list {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 15px;

          .left-input,
          .right-input {
            width: 260px;
            .el-select {
              width: 100%;
            }
          }

          .operator {
            width: 50px;
            text-align: center;
            color: #409eff;
          }

          .del {
            width: 20px;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
