<template>
  <div class="graph-container">
    <component :is="stencil" @dragstart="dragstart"></component>
    <div class="main" ref="main"></div>

    <el-drawer
      title="单元配置"
      :visible.sync="showPanel"
      :append-to-body="true"
      :modal="false"
      size="400px"
    >
      <component v-if="showPanel" :is="panelType"></component>
    </el-drawer>

    <el-drawer
      title="基本设置"
      :visible.sync="showTypePanel"
      :append-to-body="true"
      :modal="false"
      size="400px"
    >
      <el-collapse v-model="typeActiveNames" class="graph-cell-panel">
        <el-collapse-item title="基本设置" name="1">
          <div class="item">
            <div class="label">名称</div>
            <div class="main">
              <el-input v-model="data.name" size="small"></el-input>
            </div>
          </div>
          <div class="item">
            <div class="label">描述</div>
            <div class="main">
              <el-input
                v-model="data.desc"
                size="small"
                type="textarea"
                :rows="4"
              ></el-input>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-drawer>
  </div>
</template>

<script>
import Graph from "./libs/Graph.js";
import EtlStencil from "./stencils/EtlStencil";
import PanelRunEdge from "./panels/PanelRunEdge.vue";
import PanelRuleEdge from "./panels/PanelRuleEdge.vue";
import Constant from "./libs/Constant";
import Tool from "./libs/Tool.js";

export default {
  components: {
    EtlStencil,
    PanelRunEdge,
    PanelRuleEdge,
  },
  props: {
    type: {
      type: Number,
      default: Constant.GRAPH_TYPE_ETL,
    },
    id: {
      type: [Number, String],
      default: "",
    },
    cells:{
      type:Array,
      default :() => {
        return []
      }
    }
  },
  data() {
    return {
      graph: null, // 图编辑器实例对象
      panelType: "", // 节点配置面板
      showPanel: false, // 节点配置面板
      showTypePanel: false, // 图编辑器基础配置面板
      typeActiveNames: ["1", "2"], // 图形基础配置活跃面板
      // 图编辑器数据
      data: {
        id: "",
        cells: [],
        type: "",
        name: "",
        desc: "",
        extra: {},
      },
    };
  },
  computed: {
    stencil: function () {
      return Constant.GRAPH_STENCILS[this.type] + "Stencil";
    },
    isEtlType: function () {
      return this.type === Constant.GRAPH_TYPE_ETL;
    },
  },
  created() {
    this.data.type = this.type;
  },
  mounted() {
    if (this.id) {
      this.getData();
    } else {
      this.initGraph();
    }
  },
  methods: {
    /**
     * @description: 获取数据
     * @return {void}
     */
    getData() {},
    /**
     * @description: 初始化图编辑器
     * @return {void}
     */
    initGraph() {
      this.data.cells = Tool.deepCopy(this.cells)
      this.graph = Graph.create(
        this,
        this.$refs.main,
        this.type,
        this.data.cells
      );
    },
    /**
     * @description: 节点拖拽开始事件
     * @param {*} e 事件
     * @param {*} sencil 节点类型
     * @return {void}
     */
    dragstart(e, sencil) {
      this.graph.handleSencilDrag(e, sencil);
    },
    /**
     * @description: 显示编辑器配置面板
     * @param {*} panelType 单元面板类型
     * @return {viod}
     */
    openPanel(panelType) {
      this.panelType = `Panel${panelType}`;
      this.showPanel = true;
    },
    /**
     * @description: 获取图编辑器数据
     * @return {Array}
     */
    toJSON() {
      this.data.cells = this.graph.graph.toJSON().cells;
      return this.data;
    },
    /**
     * @description: 显示图编辑器配置面板
     * @return {void}
     */
    openTypePanel() {
      this.showTypePanel = true;
    },
    /**
     * @description: 保存图编辑器数据
     * @return {void}
     */
    submit() {
      this.data.cells = this.graph.graph.toJSON().cells;

      if (this.data.cells.length < 1) {
        return this.$message.error("请至少绘制一个节点");
      }

      if (!this.data.name) {
        return this.$message.error("名称不能为空");
      }

      this.$loading();

      if (this.data.id) {
        // graphApi
        //   .edit(this.data)
        //   .then((res) => {
        //     this.$loading().close();
        //     if (res.data.code === 200) {
        //       this.$message.success('操作成功');
        //       this.getData();
        //     }
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //     this.$loading().close();
        //   });
      } else {
        // graphApi
        //   .add(this.data)
        //   .then((res) => {
        //     this.$loading().close();
        //     if (res.data.code === 200) {
        //       this.$message.success('操作成功');
        //       this.data.id = res.data.data.id;
        //       this.getData();
        //     }
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //     this.$loading().close();
        //   });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.graph-container {
  flex: 1;
  overflow: hidden;
  display: flex;

  .main {
    flex: 1;
  }
}
</style>

<style lang="less">
.etl-cell-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #409eff;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  .content {
    flex: 1;
    height: 20px;
    line-height: 20px;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    i {
      color: #409eff;
    }
  }
}

.etl-cell-panel {
  .el-drawer__header {
    padding: 15px;
    margin-bottom: 0;
    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .el-input {
        width: 150px;
      }
    }
  }
  .main {
    height: 100%;
    width: 100%;
    display: flex;
    border-top: 1px solid #e9e9e9;
    box-sizing: border-box;
    overflow: hidden;

    .primary-btn {
      padding: 10px 0;
      color: #409eff;
    }

    .title {
      color: #909399;
      padding-bottom: 15px;
    }

    .m-left {
      padding: 15px;
      overflow: auto;
      width: 260px;
      box-sizing: border-box;
      border-right: 1px solid #e9e9e9;
    }

    .m-right {
      overflow: auto;
    }
  }
}
</style>
