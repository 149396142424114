/*
 * @Author: ttheitao
 * @Description: x6单元注册器
 * @Date: 2022-07-17 17:09:40
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-30 18:54:37
 */
import { Graph as X6Graph } from "@antv/x6";
import '@antv/x6-vue-shape';
import Start from "../customNodes/Start.vue";
import End from "../customNodes/End.vue";
import EtlInput from '../customNodes/EtlInput.vue';
import EtlOutput from '../customNodes/EtlOutput.vue';
import EtlUnionAll from '../customNodes/EtlUnionAll.vue';
import EtlAggregate from '../customNodes/EtlAggregate.vue';
import EtlFieldConfig from '../customNodes/EtlFieldConfig.vue';
import EtlFilter from '../customNodes/EtlFilter.vue';
import EtlJoin from '../customNodes/EtlJoin.vue';

export default class CustomCells {
  static isRegister = false;

  // 自定义vue节点 节点的属性在节点创建方法 create{name}Node 中
  static customVueNodes = [
    {
      name: "Start",
      options: {
        template: `<start></start>`,
        components: {
          Start,
        },
      },
      overwrite: true
    },
    {
      name: "End",
      options: {
        template: `<end></end>`,
        components: {
          End,
        }
      },
      overwrite: true
    },
    {
      name: "EtlInput",
      options: {
        template: `<etl-input></etl-input>`,
        components: {
          EtlInput,
        }
      },
      overwrite: true
    },
    {
      name: "EtlOutput",
      options: {
        template: `<etl-output></etl-output>`,
        components: {
          EtlOutput,
        }
      },
      overwrite: true
    },
    {
      name: "EtlUnionAll",
      options: {
        template: `<etl-union-all></etl-union-all>`,
        components: {
          EtlUnionAll,
        }
      },
      overwrite: true
    },
    {
      name: "EtlAggregate",
      options: {
        template: `<etl-aggregate></etl-aggregate>`,
        components: {
          EtlAggregate,
        }
      },
      overwrite: true
    },
    {
      name: "EtlFieldConfig",
      options: {
        template: `<etl-field-config></etl-field-config>`,
        components: {
          EtlFieldConfig,
        }
      },
      overwrite: true
    },
    {
      name: "EtlFilter",
      options: {
        template: `<etl-filter></etl-filter>`,
        components: {
          EtlFilter,
        }
      },
      overwrite: true
    },
    {
      name: "EtlJoin",
      options: {
        template: `<etl-join></etl-join>`,
        components: {
          EtlJoin,
        }
      },
      overwrite: true
    },
  ];

  // 自定义边
  static customEdges = [
    {
      name: 'NormalEdge',
      options: {
        inherit: 'edge',
        attrs: {
          line: {
            stroke: '#a0a0a0',
          },
        },
        // 业务数据
        data: {},
        zIndex: 0,
      },
      overwrite: true
    },
    {
      name: 'RunEdge',
      options: {
        inherit: 'edge',
        attrs: {
          line: {
            stroke: '#a0a0a0',
          },
        },
        labels: [
          {
            markup: [
              {
                tagName: 'rect',
                selector: 'labelBody',
              },
              {
                tagName: 'text',
                selector: 'labelText',
              },
            ],
            attrs: {
              labelText: {
                text: '优先级：1',
                fill: '#909399',
                textAnchor: 'middle',
                textVerticalAnchor: 'middle',
              },
              labelBody: {
                ref: 'labelText',
                refX: -6,
                refY: -3,
                refWidth: '100%',
                refHeight: '100%',
                refWidth2: 12,
                refHeight2: 6,
                stroke: '#909399',
                fill: '#fff',
                strokeWidth: 1,
                rx: 5,
                ry: 5,
              },
            },
            position: {
              distance: 0.5,
              args: {
                keepGradient: true,
                ensureLegibility: true,
              },
            },
          },
        ],
        // 业务数据
        data: {
          // 优先级
          priority: 1,
          // 是否启用
          enabled: true,
        },
        zIndex: 0,
      },
      overwrite: true
    },
    {
      name: 'RuleEdge',
      options: {
        inherit: 'edge',
        attrs: {
          line: {
            stroke: '#67C23A',
          },
        },
        labels: [
          {
            markup: [
              {
                tagName: 'rect',
                selector: 'labelBody',
              },
              {
                tagName: 'text',
                selector: 'labelText',
              },
            ],
            attrs: {
              labelText: {
                text: '优先级：1',
                fill: '#909399',
                textAnchor: 'middle',
                textVerticalAnchor: 'middle',
              },
              labelBody: {
                ref: 'labelText',
                refX: -6,
                refY: -3,
                refWidth: '100%',
                refHeight: '100%',
                refWidth2: 12,
                refHeight2: 6,
                stroke: '#909399',
                fill: '#fff',
                strokeWidth: 1,
                rx: 5,
                ry: 5,
              },
            },
            position: {
              distance: 0.5,
              args: {
                keepGradient: true,
                ensureLegibility: true,
              },
            },
          },
        ],
        // 业务数据
        data: {
          // 规则条件的附属边，根据条件执行的结果走 pass | fail
          type: 'pass',
          // 优先级
          priority: 1,
          // 是否启用
          enabled: true,
        },
        zIndex: 0,
      },
      overwrite: true
    }
  ];

  /**
   * @description: 注册单元到X6
   * @return {void}
   */
  static register() {
    if (!CustomCells.isRegister) {
      CustomCells.customVueNodes.forEach(node => {
        X6Graph.registerVueComponent(node.name, node.options, node.overwrite);
      });

      CustomCells.customEdges.forEach(edge => {
        X6Graph.registerEdge(edge.name, edge.options, edge.overwrite);
      });

      CustomCells.isRegister = true;
    }
  }
}