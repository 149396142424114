<!--
 * @Author: ttheitao
 * @Description: 数据筛选
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-16 17:52:44
-->

<template>
  <div class="etl-cell-container">
    <div class="content">
      <i class="iconfont iconshujushaixuan"></i>
      {{ nodeData.label }}
    </div>
    <el-drawer
      class="etl-cell-panel etl-cell-filter-panel"
      :visible.sync="showPanel"
      :append-to-body="panel.modalAppendToBody"
      :modal="panel.modal"
      :direction="panel.direction"
      :size="panel.size"
      :show-close="panel.showClose"
      @close="onDrawerClose"
    >
      <template slot="title">
        <div class="type">
          <i class="iconfont iconshujushaixuan"></i>
          数据筛选
        </div>
        <div class="tabs-pane-top">
          <el-radio-group v-model="activeName">
            <el-radio-button label="setting"> 数据筛选 </el-radio-button>
            <el-radio-button label="preview"> 数据预览 </el-radio-button>
          </el-radio-group>
        </div>
        <div class="name">
          <span>节点名称：</span>
          <el-input v-model="nodeData.label"></el-input>
        </div>
      </template>
      <div class="main" v-show="activeName == 'preview'">
        <DataPreview :nodeId="node.id" ref="preview"></DataPreview>
      </div>
      <div class="main" v-show="activeName == 'setting'">
        <div class="m-left filter-type">
          <div class="title">筛选出符合以下条件的数据</div>
          <el-radio-group
            v-model="nodeData.filterType"
            @change="onFilterTypeChange"
          >
            <el-radio-button
              v-for="(item, index) in filterTypes"
              :key="index"
              :label="item.type"
            >
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
          <div class="title">添加筛选字段</div>
          <div class="fields">
            <div
              class="item"
              v-for="(item, index) in nodeData.objectFields"
              :key="index"
            >
              <div class="name">
                <i class="iconfont icona-wendangjilu"></i> {{ item.name }}
              </div>
              <span class="add" @click="addCondition(item)" v-show="!isNestFilter(nodeData.filterType)">
                <i class="iconfont iconxinzeng2"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="m-right condition-box">
          <div class="list" v-if="!isNestFilter(nodeData.filterType)">
            <div
              class="item"
              v-for="(item, index) in nodeData.conditions"
              :key="index"
            >
              <!-- 左侧值 -->
              <el-input
                class="left-value"
                v-model="item.name"
                size="small"
                disabled
              ></el-input>

              <!-- 运算符 -->
              <el-select
                class="operator"
                size="small"
                :value="item.operator"
                placeholder="请选择"
                @change="onOperatorChange($event, item)"
              >
                <el-option
                  v-for="(item2, index) in operators"
                  :key="index"
                  :label="item2.name"
                  :value="item2.type"
                  :disabled="
                    isBetweenOperator(item2.type) &&
                    ['datetime', 'integer'].indexOf(item.type) === -1
                  "
                >
                </el-option>
              </el-select>

              <!-- 右侧日期时间范围 -->
              <el-date-picker
                class="right-between-value"
                v-if="
                  isBetweenOperator(item.operator) && item.type === 'datetime'
                "
                v-model="item.rightValue"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                :value-format="dateValueFormat"
              >
              </el-date-picker>

              <!-- 右侧数字范围 -->
              <div
                v-else-if="
                  isBetweenOperator(item.operator) && item.type === 'integer'
                "
                class="right-between-value"
              >
                <el-input
                  type="number"
                  placeholder="请输入范围"
                  v-model="item.rightValue[0]"
                />
                <span class="divider">-</span>
                <el-input
                  type="number"
                  placeholder="请输入范围"
                  v-model="item.rightValue[1]"
                />
              </div>

              <!-- 右侧数字包含 -->
              <div
                v-else-if="isInOperator(item.operator)"
                class="right-in-value"
              >
                <el-popover placement="top">
                  <div
                    style="
                      width: 200px;
                      height: 300px;
                      overflow: auto;
                      padding-right: 5px;
                    "
                  >
                    <div
                      class="right-in-value-item"
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 5px;
                      "
                      v-for="(valueItem, valueIndex) in item.rightValue"
                      :key="valueIndex"
                    >
                      <el-input
                        v-if="item.type === 'integer'"
                        style="width: 200px"
                        type="number"
                        v-model="item.rightValue[valueIndex]"
                      ></el-input>

                      <el-date-picker
                        v-else
                        v-model="item.rightValue[valueIndex]"
                        type="datetime"
                        placeholder="选择日期时间"
                        :value-format="dateValueFormat"
                      >
                      </el-date-picker>
                      <span
                        class="del"
                        style="margin-left: 5px"
                        @click="delInItem(item.rightValue, index)"
                      >
                        <i class="iconfont iconc-trash-can"></i>
                      </span>
                    </div>
                    <div class="add" @click="addInItem(item.rightValue)">
                      <i class="iconfont iconxinzeng2"></i> 添加包含项
                    </div>
                  </div>
                  <el-button slot="reference" style="width: 350px">
                    编辑
                  </el-button>
                </el-popover>
              </div>

              <!-- 日期 -->
              <el-date-picker
                class="right-value"
                v-else-if="
                  item.type === 'datetime' && !isEmptyOperator(item.operator)
                "
                v-model="item.rightValue"
                type="datetime"
                placeholder="选择日期时间"
                :value-format="dateValueFormat"
              >
              </el-date-picker>

              <!-- 其他 -->
              <el-input
                class="right-value"
                v-else
                v-model="item.rightValue"
                :type="item.type === 'integer' ? 'number' : 'text'"
                :disabled="isEmptyOperator(item.operator)"
                size="small"
              ></el-input>
              <span class="del" @click="delCondition(index)">
                <i class="iconfont iconc-trash-can"></i>
              </span>
            </div>
          </div>
          <el-form v-else>
          <views-tree-form
            :showTitle="false"
            :obuuid="node.id"
            :formData="
              nodeData.conditions.length ? nodeData.conditions : null
            "
            :dictionaryList="[]"
            :relationshipList="[]"
            :fieldList="fieldList"
            :needVirtual="false"
            @input="nestTypeInput"
          >
          </views-tree-form>
          </el-form>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Constant from "../libs/Constant";
import BaseOptions from "./mixins/BaseOptions.js";
import { RadioGroup, RadioButton, Popover } from "element-ui";
import DataPreview from "../components/DataPreview.vue";
import viewsTreeForm from "@/views/objects/components/viewstreeForm.vue";
import Tool from "../libs/Tool";

export default {
  name: "EtlFilter",
  mixins: [BaseOptions],
  components: {
    "el-radio-group": RadioGroup,
    "el-radio-button": RadioButton,
    "el-popover": Popover,
    DataPreview,
    viewsTreeForm,
  },
  data() {
    return {
      tableData: [],
      dateValueFormat: "yyyy-MM-dd HH:mm:ss",
      activeName: "setting",
    };
  },
  watch: {
    activeName(val) {
      if (val === "preview") {
        this.$refs.preview.reload();
      }
    },
  },
  computed: {
    filterTypes: function () {
      return Constant.ETL_FILTER_TYPES;
    },
    operators: function () {
      return Constant.ETL_OPERATORS;
    },
    fieldList: function () {
      let list = Tool.deepCopy(this.nodeData.objectFields);
      for (let i in list) {
        list[i].uuid = list[i].code;
        list[i].id = parseInt(i);
      }
      return list;
    },
  },
  mounted() {},
  methods: {
    /**
     * @description: 添加条件
     * @param {Object} item 字段
     * @return {*}
     */
    addCondition(item) {
      let condition = {
        name: item.name,
        code: item.code,
        operator: Constant.ETL_OPERATOR_EQ,
        type: item.type,
        rightValue: "",
      };
      this.resetConditionValue(condition);

      this.nodeData.conditions.push(condition);
    },
    /**
     * @description: 判断条件类型是否为区间
     * @param {String} operator 条件操作符
     * @return {Boolean}
     */
    isBetweenOperator(operator) {
      return [
        Constant.ETL_OPERATOR_BETWEEN,
        Constant.ETL_OPERATOR_NOTBETWEEN,
      ].indexOf(operator) > -1
        ? true
        : false;
    },
    /**
     * @description: 判断条件类型是否为包含
     * @param {String} operator 条件操作符
     * @return {Boolean}
     */
    isInOperator(operator) {
      return [Constant.ETL_OPERATOR_IN, Constant.ETL_OPERATOR_NOTIN].indexOf(
        operator
      ) > -1
        ? true
        : false;
    },
    /**
     * @description: 判断条件类型是否为空
     * @param {String} operator 条件操作符
     * @return {Boolean}
     */
    isEmptyOperator(operator) {
      return [
        Constant.ETL_OPERATOR_EMPTY,
        Constant.ETL_OPERATOR_NOTEMPTY,
      ].indexOf(operator) > -1
        ? true
        : false;
    },
    /**
     * @description: 删除条件
     * @param {Number} index 索引
     * @return {void}
     */
    delCondition(index) {
      this.nodeData.conditions.splice(index, 1);
    },
    /**
     * @description: 条件操作符改变
     * @param {any} value 选中值
     * @param {Object} condition 条件
     * @return {void}
     */
    onOperatorChange(value, condition) {
      if (
        (this.isInOperator(condition.operator) && this.isInOperator(value)) ||
        (this.isBetweenOperator(condition.operator) &&
          this.isBetweenOperator(value))
      ) {
        //TODO 包含、区间、其他类型重置逻辑
        condition.operator = value;
      } else {
        condition.operator = value;
        this.resetConditionValue(condition);
      }
    },
    /**
     * @description: 重置条件默认值
     * @param {Object} condition 条件
     * @return {void}
     */
    resetConditionValue(condition) {
      switch (condition.operator) {
        case Constant.ETL_OPERATOR_EQ:
        case Constant.ETL_OPERATOR_NOTEQ:
        case Constant.ETL_OPERATOR_GT:
        case Constant.ETL_OPERATOR_GTE:
        case Constant.ETL_OPERATOR_LT:
        case Constant.ETL_OPERATOR_LTE:
        case Constant.ETL_OPERATOR_LIKE:
        case Constant.ETL_OPERATOR_NOTLIKE:
        case Constant.ETL_OPERATOR_EMPTY:
        case Constant.ETL_OPERATOR_NOTEMPTY:
          condition.rightValue = "";
          break;
        case Constant.ETL_OPERATOR_IN:
        case Constant.ETL_OPERATOR_NOTIN:
          condition.rightValue = [];
          break;
        case Constant.ETL_OPERATOR_BETWEEN:
        case Constant.ETL_OPERATOR_NOTBETWEEN:
          if (condition.type === "integer") {
            condition.rightValue = [null, null];
          } else if (condition.type === "datetime") {
            condition.rightValue = ["", ""];
          }

          break;
      }
    },
    /**
     * @description: 添加一项右侧值
     * @param {Array} rightValue 右侧值
     * @return {*}
     */
    addInItem(rightValue) {
      rightValue.push(null);
    },
    /**
     * @description: 删除右值项
     * @param {*} rightValue
     * @param {*} index
     * @return {void}
     */
    delInItem(rightValue, index) {
      rightValue.splice(index, 1);
    },
    /**
     * @description: 判断是否为复杂筛选类型
     * @return {void}
     */
    isNestFilter(type) {
      return type === Constant.ETL_FILTER_TYPE_NEST;
    },
    /**
     * @description: 过滤类型改变
     * @return {void}
     */
    onFilterTypeChange() {
      this.nodeData.conditions = [];
    },
    /**
     * @description: 复杂条件改变
     * @param {Array} value
     * @return {*}
     */
    nestTypeInput(value) {
      this.nodeData.conditions = value;
    },
  },
};
</script>

<style lang="less">
.etl-cell-filter-panel {
  .tabs-pane-top {
    flex: 1;
  }
  .main {
    .filter-type {
      display: flex;
      flex-direction: column;
      .el-radio-group {
        margin-bottom: 15px;
      }
      .fields {
        flex: 1;
        width: 100%;
        overflow: auto;
        .item {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          .add {
            color: #409eff;
            margin-right: 10px;
          }
        }
      }
    }

    .condition-box {
      padding: 15px;
      flex: 1;

      .list {
        width: 800px;
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .left-value {
            width: 200px;
          }

          .operator {
            margin: 0 5px;
            width: 120px;
          }

          .right-value {
            width: 350px;
          }

          .right-between-value {
            width: 350px;
            display: flex;
            justify-content: center;
            align-items: center;

            .el-input {
              flex: 1;
            }

            .divider {
              padding: 0 5px;
            }
          }

          .right-in-value {
            width: 350px;
          }

          .del {
            margin-left: 5px;
          }
        }
      }
    }

    .add-condition {
      color: #409eff;
      width: 150px;
    }
  }
}
</style>
