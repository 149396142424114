<!--
 * @Author: ttheitao
 * @Description: ETL
 * @Date: 2022-08-30 09:45:22
 * @LastEditors: zx
 * @LastEditTime: 2022-10-08 16:21:38
-->
<template>
  <div
    style="height: 100%; text-align: right"
    class="etl-main"
    v-loading="allLoading"
  >
    <div class="etl-top">
      <div class="etl-top-left">
        <div class="icon" @click="$router.back()">
          <i class="el-icon-back"></i>
        </div>
        <div class="name-input">
          <el-input
            v-model="name"
            class="title"
            size="small"
            placeholder="请输入数据流名称"
          ></el-input>
        </div>
      </div>
      <div class="etl-top-main">
        <!-- <span class="title">这是个测试标题</span> -->
        <!-- <el-input v-model="name" class="title"></el-input> -->
      </div>

      <div class="etl-top-right">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-folder-checked"
          class="right-button"
          @click="onSave"
          >保存</el-button
        >
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-folder-opened"
          class="right-button"
          @click="onExchange"
          v-if="!id"
          >切换</el-button
        >
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-folder-add"
          class="right-button"
          @click="onAdd"
          v-if="!id"
          >新增</el-button
        >
        <!-- 复制一直有，这个功能放在里面 -->
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-document-copy"
          class="right-button"
          @click="onCopy"
          v-if="id !== 'add'"
          >复制</el-button
        >
        <el-button
          type="danger"
          size="mini"
          class="right-button"
          @click="onDelete"
          icon="el-icon-delete"
          >删除</el-button
        >
        <!-- <el-button type="danger" class="right-button" @click="testGetData"
          >查看数据</el-button
        > -->
      </div>
    </div>
    <!-- <el-button
      type="primary"
      @click="testGetData"
      style="position: absolute; right: 0; top: 0; z-index: 1"
    >
      获取data
    </el-button> -->
    <Graph
      class="etl-graph"
      :cells="cells"
      :key="graphKey"
      ref="graph"
      :type="type"
      v-if="showGraph"
    ></Graph>

    <el-dialog
      class="etl-choose-etl"
      title="选择数据流"
      v-if="showSelector"
      
      :visible.sync="showSelector"
      :append-to-body="true"
      width="600px"
      top="-10vh"
      :show-close="false"
      :close-on-click-modal="true"
      :close-on-press-escape="false"
    >
      <div class="etl-choose-main" v-loading="selectLoading">
        <el-input
          placeholder="搜索ETL"
          prefix-icon="el-icon-search"
          v-model="search"
          @input="handleEtlList"
        >
        </el-input>
        <!-- data sources select area -->
        <article>
          <div
            v-for="(item, index) in etlList"
            :key="index"
            :class="item.path == graphId ? 'active-item' : 'item'"
            @click="onChooseEtl(item)"
          >
            <i class="iconfont icona-wendangjilu"></i> {{ item.name }} -
            {{ item.path }}
          </div>
        </article>

        <el-pagination
          background
          layout="total,prev, next,jumper"
          v-show="etlList.length"
          :total="etlTotal"
          @current-change="handleEtlList"
          :current-page.sync="page"
          class="etl-choose-page"
        ></el-pagination>
      </div>

      <div slot="title" class="title-box">
        <span class="dialog-title">请选择一个数据流 </span>
        <div>
          <i class="iconfont iconcujia" @click="onAdd"></i>
          <i class="iconfont iconc-close" @click="showSelector = false"></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import objects from "@/api/apiSaasStore";
import Graph from "./Index.vue";
import Constant from "./libs/Constant.js";
import Tool from "./libs/Tool";
export default {
  components: {
    Graph,
  },
  props: {
    id: {
      type: [Number, String],
      default: "", // add 的时候自动调新增
    },
  },
  data() {
    return {
      type: Constant.GRAPH_TYPE_ETL,
      allLoading: false,

      name: "请先选择一个数据流",
      graphId: "",
      cells: [],

      etlList: [],

      showSelector: false,
      search: "",
      page: 1,
      size: 10,
      selectLoading: false,
      etlTotal: 0,

      showGraph: true,

      graphKey: "ok",
    };
  },
  watch: {
    id: function () {
      this.handleInit();
    },
  },
  methods: {
    testGetData() {
      console.log(this.toJSON());
    },
    toJSON() {
      return this.$refs.graph.toJSON();
    },
    submit() {
      this.$refs.graph.submit();
    },
    openTypePanel() {
      this.$refs.graph.openTypePanel();
    },

    /**
     * @description: 复制按钮
     */
    onCopy() {
      if (!this.graphId) {
        Message.error("请先打开一个数据流或者保存之后再复制！");
        return;
      }
      console.log("copy");
      this.graphId = "";
      this.name = this.name + "_copy";
    },

    /**
     * @description: 保存按钮
     */
    onSave() {
      if (!this.name) {
        Message.error("请输入数据流名称！");
        return;
      }
      this.allLoading = true;
      const object_uuid = this.$route.query.obuuid;
      if (!this.graphId) {
        // 新增
        objects
          .etlAdd(this.name, this.toJSON().cells, object_uuid)
          .then((res) => {
            this.allLoading = false;
            if (res.data.code == 200) {
              Message.success("保存成功！");
              this.graphId = res.data.data.path;
              // 外部刷新列表
              this.$emit("reloadList");
            }
          })
          .catch(() => {
            this.allLoading = false;
          });
      } else {
        // 编辑
        objects
          .etlEdit(this.graphId, this.name, this.toJSON().cells, object_uuid)
          .then((res) => {
            this.allLoading = false;
            if (res.data.code == 200) {
              Message.success("保存成功！");
            }
          })
          .catch(() => {
            this.allLoading = false;
          });
      }
    },

    /**
     * @description: 切换按钮
     */
    onExchange() {
      this.showSelector = true;
      this.$nextTick(() => {
        this.search = "";
        this.page = 1;
        this.size = 10;
        this.handleEtlList();
      });
    },

    /**
     * @description: 新增按钮
     */
    onAdd() {
      this.showSelector = false;
      this.name = "";
      this.graphId = "";
      this.cells = [];
      this.handleReloadGraph();
    },

    /**
     * @description: 删除按钮
     */
    onDelete() {
      let graphId = this.graphId;
      if (!graphId) {
        Message.info("当前处于新增模式，无法进行删除");
        return;
      }
      this.allLoading = true;
      this.$confirm("您确认要删除数据流【" + this.name + "】吗？", "请确认")
        .then(() => {
          objects
            .etlDelete(graphId)
            .then((res) => {
              this.allLoading = false;
              if (res.data.code == 200) {
                Message.success("删除成功");
                this.onAdd();
                if (!this.id) {
                  this.onExchange();
                }
                // 外部刷新列表
                this.$emit("reloadList");
              }
            })
            .catch(() => {
              this.allLoading = false;
            });
        })
        .catch(() => {
          this.allLoading = false;
        });
    },

    /**
     * @description: 获取etl列表用于切换
     */
    handleEtlList() {
      this.etlList = [];
      this.selectLoading = true;
      const object_uuid = this.$route.query.obuuid;
      objects
        .etlList(this.page, this.size, this.search, object_uuid)
        .then((res) => {
          this.selectLoading = false;
          if (res.data.code == 200) {
            this.etlList = res.data.data.data;
            this.etlTotal = res.data.data.total;
          }
        })
        .catch(() => {
          this.selectLoading = false;
        });
    },

    /**
     * @description: 选择了一个ETL配置并切换到编辑器中
     * @param {Object} 选中要切换的ETL
     */
    onChooseEtl(info) {
      this.showSelector = false;
      this.allLoading = true;
      objects
        .etlInfo(info.path)
        .then((res) => {
          this.allLoading = false;
          if (res.data.code == 200) {
            this.graphId = res.data.data.path;
            this.cells = res.data.data.cells;
            this.name = res.data.data.name;
            this.handleReloadGraph();
          }
        })
        .catch(() => {
          this.allLoading = false;
        });
    },

    /**
     * @description: 重载图形
     */
    handleReloadGraph() {
      // 给一个新的key 将重新渲染组件
      this.graphKey = "graph" + Tool.randomRange(10000, 40000);
    },
    handleInit() {
      if (this.id) {
        if (this.id === "add") {
          this.onAdd();
        } else {
          this.onChooseEtl({ path: this.id });
        }
      } else {
        this.onExchange();
      }
    },
  },
  mounted() {
    this.handleInit();
  },
};
</script>

<style lang="less" scoped>
 @import "./dialogHeaderStyle.less";
.etl-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .etl-top {
    height: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 28px;
    text-align: left;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    overflow: hidden;
    .etl-top-left {
      padding-left: 10px;
      display: flex;
      flex-direction: row;
      .icon {
        line-height: 60px;
        color: #409eff;
      }
      .name-input {
        flex: 1;
        .title {
          font-size: 16px;
          line-height: 60px;
          padding-left: 10px;
          font-weight: bold;
          min-width: 400px;
          padding: 0;
          :deep(input) {
            border: none;
          }
        }
      }
    }
    .etl-top-main {
      flex: 1;
      text-align: center;
      .title {
        font-size: 22px;
        line-height: 60px;
        padding-left: 10px;
        font-weight: bold;
      }
    }
    .etl-top-right {
      padding-right: 10px;
      line-height: 50px;
      .right-button {
        min-width: 80px;
        font-size: 14px;
      }
    }
  }
  .etl-main {
    flex: 1;
  }
}
.etl-choose-main {
  height: 100%;
  .item {
    padding: 12px;
    &:hover {
      color: #409eff;
      background-color: #e8f3fe;
    }
  }
  .active-item {
    padding: 12px;
    color: #409eff;
    background-color: #e8f3fe;
  }
  article {
    margin-top: 10px;
    overflow: auto;
    height: 100%;
    padding-top: 5px;
  }
  .etl-choose-page {
    margin-top: 5px;
    text-align: center;
  }
}

.title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dialog-title {
    font-size: 18px;
  }
  div {
    i {
      margin-left: 8px;
      font-size: 18px;
    }
  }
}
</style>
