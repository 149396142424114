/*
 * @Author: ttheitao
 * @Description: 常量&枚举
 * @Date: 2022-08-31 13:56:15
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-09-15 17:50:41
 */
export default class Constant {
  // 图形类型
  static GRAPH_TYPE_BLUEPRINT = 1;
  static GRAPH_TYPE_ETL = 2;
  static GRAPH_TYPE_WROKFLOW = 3;
  // 全部图形类型
  static GRAPH_TYPES = [
    { type: Constant.GRAPH_TYPE_BLUEPRINT, name: '蓝图' },
    { type: Constant.GRAPH_TYPE_ETL, name: 'ETL' },
    { type: Constant.GRAPH_TYPE_WROKFLOW, name: '流程' }
  ]
  // 全部图形类型面板
  static GRAPH_STENCILS = {
    [Constant.GRAPH_TYPE_BLUEPRINT]: "Blueprint",
    [Constant.GRAPH_TYPE_ETL]: "Etl",
    [Constant.GRAPH_TYPE_WROKFLOW]: "Workflow",
  }

  // ETL连接类型
  static ETL_JOIN_TYPE_INNER = 'inner';
  static ETL_JOIN_TYPE_LEFT = 'left';
  static ETL_JOIN_TYPE_RIGHT = 'right';
  // TEL 全部连接类型
  static ETL_JOIN_TYPES = [
    { type: Constant.ETL_JOIN_TYPE_INNER, name: '内连接' },
    { type: Constant.ETL_JOIN_TYPE_LEFT, name: '左连接' },
    { type: Constant.ETL_JOIN_TYPE_RIGHT, name: '右连接' }
  ]

  // ETL聚合类型
  static ETL_AGGREGATE_SUM = 'sum';
  static ETL_AGGREGATE_COUNT = 'count';
  static ETL_AGGREGATE_AVG = 'avg';
  static ETL_AGGREGATE_MAX = 'max';
  static ETL_AGGREGATE_MIN = 'min';
  // ETL全部聚合类型
  static ETL_AGGREGATES = [
    { type: Constant.ETL_AGGREGATE_SUM, name: '总和' },
    { type: Constant.ETL_AGGREGATE_COUNT, name: '总数' },
    { type: Constant.ETL_AGGREGATE_AVG, name: '平均数' },
    { type: Constant.ETL_AGGREGATE_MAX, name: '最大值' },
    { type: Constant.ETL_AGGREGATE_MIN, name: '最小值' },
  ]

  //ETL数据条件类型
  static ETL_FILTER_TYPE_AND = 'and';
  static ETL_FILTER_TYPE_OR = 'or';
  static ETL_FILTER_TYPE_NEST = 'nest';
  static ETL_FILTER_TYPES = [
    { type: Constant.ETL_FILTER_TYPE_AND, name: '所有' },
    { type: Constant.ETL_FILTER_TYPE_OR, name: '任一' },
    { type: Constant.ETL_FILTER_TYPE_NEST, name: '复杂' },
  ]

  //ETL数据比较类型
  static ETL_OPERATOR_EQ = 'eq';
  static ETL_OPERATOR_NOTEQ = 'noteq';
  static ETL_OPERATOR_GT = 'gt';
  static ETL_OPERATOR_GTE = 'gte';
  static ETL_OPERATOR_LT = 'lt';
  static ETL_OPERATOR_LTE = 'lte';
  static ETL_OPERATOR_LIKE = 'like';
  static ETL_OPERATOR_NOTLIKE = 'notlike';
  static ETL_OPERATOR_IN = 'in';
  static ETL_OPERATOR_NOTIN = 'notin';
  static ETL_OPERATOR_BETWEEN = 'between';
  static ETL_OPERATOR_NOTBETWEEN = 'notbetween';
  static ETL_OPERATOR_EMPTY = 'empty';
  static ETL_OPERATOR_NOTEMPTY = 'notempty';
  //ETL全部数据比较类型
  static ETL_OPERATORS = [
    { type: Constant.ETL_OPERATOR_EQ, name: '等于' },
    { type: Constant.ETL_OPERATOR_NOTEQ, name: '不等于' },
    { type: Constant.ETL_OPERATOR_GT, name: '大于' },
    { type: Constant.ETL_OPERATOR_GTE, name: '大于等于' },
    { type: Constant.ETL_OPERATOR_LT, name: '小于' },
    { type: Constant.ETL_OPERATOR_LTE, name: '小于等于' },
    { type: Constant.ETL_OPERATOR_LIKE, name: '类似' },
    { type: Constant.ETL_OPERATOR_NOTLIKE, name: '不类似' },
    { type: Constant.ETL_OPERATOR_IN, name: '包含' },
    { type: Constant.ETL_OPERATOR_NOTIN, name: '不包含' },
    { type: Constant.ETL_OPERATOR_BETWEEN, name: '区间包含' },
    { type: Constant.ETL_OPERATOR_NOTBETWEEN, name: '区间不包含' },
    { type: Constant.ETL_OPERATOR_EMPTY, name: '为空' },
    { type: Constant.ETL_OPERATOR_NOTEMPTY, name: '不为空' },
  ]

  //ETL字段类型
  static ETL_FIELD_REAL = 1;
  static ETL_FIELD_COMPUTE = 2;
  //ETL全部字段类型
  static ETL_ALL_FIELD_TYPES = [
    { type: Constant.ETL_FIELD_REAL, name: '实体字段' },
    { type: Constant.ETL_FIELD_COMPUTE, name: '计算字段' },
  ];

  // ETL节点
  static ETL_CELL_INPUT = 'EtlInput';
  static ETL_CELL_OUTPUT = 'EtlOutput';
  static ETL_CELL_UNIONALL = 'EtlUnionAll';
  static ETL_CELL_JOIN = 'EtlJoin';
  static ETL_CELL_FILTER = 'EtlFilter';
  static ETL_CELL_FIELD_CONFIG = 'EtlFieldConfig';
  static ETL_CELL_AGGREGATE = 'EtlAggregate';
  // ETL全部节点
  static GRAPH_ETL_CELLS_ARRAY = [
    { type: Constant.ETL_CELL_INPUT, name: '输入' },
    { type: Constant.ETL_CELL_OUTPUT, name: '输出' },
    { type: Constant.ETL_CELL_UNIONALL, name: '多源合并' },
    { type: Constant.ETL_CELL_JOIN, name: '横向连接' },
    { type: Constant.ETL_CELL_FILTER, name: '数据筛选' },
    { type: Constant.ETL_CELL_FIELD_CONFIG, name: '字段配置' },
    { type: Constant.ETL_CELL_AGGREGATE, name: '分组汇总' },
  ];
  static GRAPH_ETL_CELLS_OBJECT = {
    [Constant.ETL_CELL_INPUT]: '输入',
    [Constant.ETL_CELL_OUTPUT]: '输出',
    [Constant.ETL_CELL_UNIONALL]: '多源合并',
    [Constant.ETL_CELL_JOIN]: '横向连接',
    [Constant.ETL_CELL_FILTER]: '数据筛选',
    [Constant.ETL_CELL_FIELD_CONFIG]: '字段配置',
    [Constant.ETL_CELL_AGGREGATE]: '分组汇总',
  };

  // ETL分组排序类型
  static ETL_GO_DESC = 'desc';
  static ETL_GO_ASC = 'asc';
  static ETL_GO_GROUP_BY = 'groupBy';
  static ETL_GO_TYPES = [
    { type: Constant.ETL_GO_ASC, name: '正序' },
    { type: Constant.ETL_GO_DESC, name: '倒序' },
    { type: Constant.ETL_GO_GROUP_BY, name: '分组' },
  ];
}