<!--
 * @Author: ttheitao
 * @Description: 输入
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: zx
 * @LastEditTime: 2022-10-11 09:34:33
-->

<template>
  <div class="etl-cell-container" v-loading="objectsLoading">
    <div class="content">
      <i class="iconfont iconshuru"></i>
      {{ nodeData.label }}
    </div>
    <el-drawer
      class="etl-cell-panel etl-cell-input-panel"
      :visible.sync="showPanel"
      :append-to-body="panel.modalAppendToBody"
      :modal="panel.modal"
      :direction="panel.direction"
      :size="panel.size"
      :show-close="panel.showClose"
      @close="onDrawerClose"
    >
      <template slot="title">
        <div class="type">
          <i class="iconfont iconshuru"></i>
          输入
          <el-button @click="onShowFields" type="primary">元字段管理</el-button>
        </div>
        <div class="name">
          <span>节点名称：</span>
          <el-input v-model="nodeData.label"></el-input>
        </div>
      </template>
      <div class="main">
        <div class="m-left metadata">
          <div class="mo-title">
            输入源
            <span class="change-btn" @click="changeInput">更改输入源</span>
          </div>
          <div class="object">
            <i class="iconfont icona-wendangjilu"></i>
            {{ curObject?.name ? curObject.name : nodeData.label }}
          </div>
          <div class="mo-title">
            已选字段 共{{ nodeData.objectFields.length }}个
          </div>
          <div class="fields" v-loading="fieldsLoading">
            <div
              class="item"
              v-for="(item, index) in nodeData.objectFields"
              :key="index"
            >
              <i class="iconfont icona-wendangjilu"></i> {{ item.name }}
            </div>
          </div>
        </div>
        <div class="m-right dataList">
          <DataPreview :nodeId="node.id" ref="DataPreview"></DataPreview>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      class="create-app-dialog"
      title="字段管理"
      v-if="showFields"
      :visible.sync="showFields"
      :append-to-body="true"
      fullscreen
    >
      <FieldsManage
        :obuuid="nodeData.objectUuid"
        :showBack="false"
        v-if="showFields"
        class="field-manage"
      ></FieldsManage>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showFields = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="create-app-dialog"
      title="添加输入源"
      v-if="showSelector"
      :visible.sync="showSelector"
      :append-to-body="true"
      width="800px"
    >
      <div class="eid-main">
        <div class="objects">
          <el-input
            placeholder="搜索数据源"
            prefix-icon="el-icon-search"
            v-model="searchObjects"
            v-if="!this.obuuid"
          >
          </el-input>
          <!-- data sources select area -->
          <article>
            <div
              v-for="(item, index) in showObjects"
              :key="index"
              class="item"
              :class="{
                active: selectorObject && item.uuid === selectorObject.uuid,
              }"
              @click="onObjectChange($event, item)"
            >
              <i class="iconfont icona-wendangjilu"></i> {{ item.name }}
            </div>
          </article>
        </div>

        <!-- after data source selected , choose fields -->
        <div class="fields" v-loading="fieldsLoading">
          <el-checkbox-group v-model="selectorUuids">
            <el-checkbox
              v-for="(item, index) in nowFields"
              :key="index"
              :label="item.uuid"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSelector = false">取 消</el-button>
        <el-button type="primary" @click="saveObject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BaseOptions from "./mixins/BaseOptions.js";
import { CheckboxGroup } from "element-ui";
import Tool from "../libs/Tool.js";
import objects from "../../../api/objects";
import collect from "collect.js";
import DataPreview from "../components/DataPreview.vue";
import FieldsManage from "@/views/objects/fieldsManage.vue";

export default {
  name: "EtlInput",
  mixins: [BaseOptions],
  components: {
    "el-checkbox-group": CheckboxGroup,
    DataPreview,
    FieldsManage,
  },
  data() {
    return {
      tableData: [],
      tableMetadata: [],
      tableTotal: 0,
      tableNowPage: 1,
      tablePageSize: 15,
      showSelector: false,
      // 对象列表
      objects: [],
      // 当前选中对象
      curObject: {
        uuid: "",
        name: "",
        fields: [],
      },

      // 当前需要选择的字段集合
      nowFields: [],

      // 选中的uuid
      selectorUuids: [],
      // 选中的对象
      selectorObject: null,
      // 当前选中字段列表
      selectorFields: [],

      // 搜索对象名称
      searchObjects: "",

      objectsLoading: true,
      fieldsLoading: false,
      tableLoading: false,

      showFields: false,
      // 对象uuid
      obuuid: '',
    };
  },
  computed: {
    // 节点已选中字段，目的是为了字段名称
    selectedFieldList: function () {
      return this.curObject.fields.filter((field) => {
        return this.nodeData.objectFields.indexOf(field.uuid) > -1;
      });
    },

    // 显示的数据源
    showObjects() {
      if (!this.searchObjects) return this.objects;
      return this.objects.filter(
        (item) => item.name.indexOf(this.searchObjects) > -1
      );
    },
  },
  methods: {
    onShowFields() {
      this.showFields = true;
    },
    /**
     * @description: 修改输入源
     * @return {*}
     */
    changeInput() {
      this.showSelector = true;
      this.selectorObject = Tool.deepCopy(this.curObject);
      this.selectorFields = Tool.deepCopy(this.nodeData.objectFields);
      this.selectorUuids = [];
      this.$nextTick(() => {
        this.selectorUuids = collect(this.selectorFields)
          .pluck("uuid")
          .toArray();
      });
    },
    /**
     * @description: 改变对象事件
     * @return {void}
     */
    onObjectChange(e, item) {
      if (this.curObject?.uuid !== item.uuid) {
        this.fieldsLoading = true;
        objects.getFields(item.uuid).then((res) => {
          this.fieldsLoading = false;
          if (res.data.code == 200) {
            this.nowFields = res.data.data.data.filter(
              (item) => item.type !== "append"
            );
            this.$nextTick(() => {
              this.selectorUuids = collect(this.nowFields)
                .pluck("uuid")
                .toArray();
            });
          }
        });
      }
      this.selectorObject = item;
    },
    /**
     * @description: 保存当前对象
     * @return {void}
     */
    saveObject() {
      this.curObject = this.selectorObject;
      console.log(this.curObject, "this.curobject");
      if(!this.curObject){
        this.$message.error('请先选择数据！')
        return
      }
      // 保存数据
      this.nodeData.objectUuid = this.curObject.uuid;
      this.nodeData.label = this.curObject.name;
      this.selectorFields = [];
      this.selectorFields = Tool.only(this.nowFields, [
        "uuid",
        "name",
        "code",
        "type",
      ])
        .filter((item) => this.selectorUuids.indexOf(item.uuid) > -1)
        .all();
      this.selectorFields.forEach((item) => (item.origin = item.code));
      this.nodeData.objectFields = [];
      this.nodeData.objectFields = Tool.deepCopy(this.selectorFields);
      // 保存数据结束

      // 清空数据
      this.selectorObject = null;
      this.selectorFields = [];
      this.selectorUuids = [];

      // 关闭 panel
      this.showSelector = false;

      this.handleTableData();
    },

    /**
     * @description: 刷新table数据
     */
    handleTableData() {
      this.$refs.DataPreview.reload();
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
  },
  mounted() {
    // 回显数据，替换已经保存的name

    // 获取对象数据
    this.objectsLoading = true;
    this.obuuid = this.getQueryString('obuuid');

    if (this.obuuid) {
      objects
        .getOne(this.obuuid)
        .then((res) => {
          this.objectsLoading = false;
          if (res.data.code === 200) {
            this.objects = [res.data.data];
            this.nowFields = res.data.data.filter(
              (item) => item.type !== "append"
            );
            this.$nextTick(() => {
              this.selectorUuids = collect(this.nowFields)
                .pluck("uuid")
                .toArray();
            });
          }
        })
        .catch(() => {
          this.objectsLoading = false;
        });
    } else {
      objects
        .getAll(1, 5000)
        .then((res) => {
          this.objectsLoading = false;
          if (res.data.code === 200) {
            this.objects = res.data.data.data;
          }
        })
        .catch(() => {
          this.objectsLoading = false;
        });
    }
  },
};
</script>
<style lang="less" scoped>
  @import "../../../manage-views/css/manageAdd.less";
</style>
<style lang="less">
.etl-cell-input-panel {
  .metadata {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .mo-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      color: #909399;
      .change-btn {
        color: #409eff;
      }
    }

    .object {
      padding-bottom: 15px;
      .iconfont {
        color: #409eff;
      }
    }

    .fields {
      flex: 1;
      width: 100%;
      overflow: auto;
      height: 230px;
      .item {
        padding: 10px 0;
      }
    }
  }
  .dataList {
    width: 100%;
  }
}
.create-app-dialog {
  .is-fullscreen {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .el-dialog__body {
    flex: 1;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    .field-manage {
      flex: 1;
      box-sizing: border-box;
    }
  }
  .eid-main {
    height: 350px;
    display: flex;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden;
    .objects {
      width: 260px;
      height: 100%;
      border-right: 1px solid #e9e9e9;
      .item {
        padding: 12px;
        &.active {
          color: #409eff;
          background-color: #e8f3fe;
        }
      }
      article {
        overflow: auto;
        height: 100%;
        padding-top: 5px;
      }
    }
    .fields {
      flex: 1;
      padding: 0 15px;
      .el-checkbox {
        display: block;
        padding: 10px 0;
      }
      overflow: auto;
    }
  }
}
</style>
