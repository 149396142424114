<!--
 * @LastEditors: ttheitao
 * @Description: 节点数据预览 :nodeid reload()
 * @FilePath: /dataview-next/src/components/graph/components/DataPreview.vue
 * @Date: 2022-09-07 14:03:31
 * @LastEditTime: 2022-12-19 17:24:41
 * @Author: lisushuang
-->
<template>
  <article class="data-preview">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      height="100%"
      v-if="objectFields.length"
      v-loading="tableLoading"
      class="etl-m-table"
    >
      <el-table-column width="50px" label="序号" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <el-table-column
        :prop="item.origin"
        align="center"
        :label="item.name"
        min-width="120"
        v-for="item in objectFields"
        :key="'maintable' + item.code"
      >
        <template slot-scope="scope">
          <div v-if="checkIfArray(scope.row[item.code])">
            共{{ JSON.parse(scope.row[item.code]).length }}条
            <el-button
              v-if="JSON.parse(scope.row[item.code]).length"
              type="primary"
              size="mini"
              style="display: inline"
              @click="showArray(scope.row[item.code])"
              >查看</el-button
            >
          </div>
          <div v-else-if="checkIfImage(scope.row[item.code])">
            <el-image
              :src="scope.row[item.code]"
              style="width: 100px; height: 100px"
              fit="fit"
              :preview-src-list="[scope.row[item.code].replace('_thumb', '')]"
            ></el-image>
          </div>
          <div
            v-else-if="scope.row[item.code] && scope.row[item.code].length > 30"
          >
            <el-tooltip :content="scope.row[item.code]" placement="top">
              <div slot="content" class="tip-content">
                <p
                  v-for="i in parseInt(scope.row[item.code].length / 40) + 1"
                  :key="item.code + 'row' + i"
                >
                  {{ scope.row[item.code].slice((i - 1) * 40, i * 40) }}
                </p>
              </div>
              <span>{{ scope.row[item.code].slice(0, 29) + "..." }}</span>
            </el-tooltip>
          </div>
          <div v-else>
            {{ scope.row[item.code] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total,prev, pager, next,jumper"
      v-show="tableData.length"
      :total="tableTotal"
      :page-size="tablePageSize"
      @current-change="reload"
      :current-page.sync="tableNowPage"
      class="etl-m-page"
    ></el-pagination>

    <el-empty
      v-if="objectFields.length === 0"
      description="尚未进行配置"
    ></el-empty>

    <!-- 数组数据查看弹窗 -->
    <el-dialog
      class="etl-input-dialog"
      title="数据内容查看"
      v-if="infoDataShow"
      :visible.sync="infoDataShow"
      :append-to-body="true"
      @closed="hideArray"
      width="800px"
      height="100%"
    >
      <div class="eid-main">
        <el-table :data="info">
          <el-table-column width="50px" label="序号" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            v-for="item in keys"
            :key="'array_show' + item"
            min-width="100px"
            :label="item"
            :prop="item"
          >
            <template slot-scope="scope">
              <div v-if="checkIfImage(scope.row[item])">
                <el-image
                  :src="scope.row[item]"
                  style="width: 100px; height: 100px"
                  fit="fit"
                  :preview-src-list="[scope.row[item].replace('_thumb', '')]"
                ></el-image>
              </div>
              <div v-else>
                {{ scope.row[item] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hideArray">确 定</el-button>
      </span>
    </el-dialog>
  </article>
</template>

<script>
import objects from "@/api/objects";
import BaseOptions from "../customNodes/mixins/BaseOptions";
import { Empty, Image, Tooltip } from "element-ui";
import isString from "lodash/isString";

export default {
  name: "DatePreview",
  mixins: [BaseOptions],
  components: { "el-empty": Empty, "el-image": Image, "el-tooltip": Tooltip },
  data() {
    return {
      tableData: [],
      tableMetadata: [],
      tableTotal: 0,
      tableNowPage: 1,
      tablePageSize: 15,
      showSelector: false,

      objectFields: [],

      infoDataShow: false,
      info: [],
      keys: [],
    };
  },
  props: {
    nodeId: {
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * @description: 重载数据
     */
    reload() {
      setTimeout(() => {
        // 准备 objectFields 数据
        this.objectFields = [];
        let find = this.graph
          .toJSON()
          .cells.filter((item) => item.id == this.nodeId);
        if (!find || !find.length || !find[0]) return;
        let nodeData = find[0].data;
        console.log(nodeData, "node data");
        if (!nodeData.objectFields || !nodeData.objectFields.length) {
          console.log("节点没有字段数据！！", this.nodeId);
          return;
        }
        this.objectFields = nodeData.objectFields;

        this.tableData = [];
        this.tableMetadata = [];
        this.tableLoading = true;

        objects
          .etlDebug(
            this.graph.toJSON().cells,
            this.nodeId,
            this.tableNowPage,
            this.tablePageSize
          )
          .then((res) => {
            this.tableLoading = false;
            if (!res.data.code === 200) return;
            this.tableData = res.data.data.data;
            this.tableTotal = res.data.data.total;
            this.tableMetadata = res.data.metadata;
          })
          .catch(() => {
            this.tableLoading = false;
          });
      }, 0);
    },

    /**
     * @description: 判断字符串是否json数组
     * @param {String} word
     * @return {Boolean}
     */
    checkIfArray(word = "") {
      try {
        let tmp = JSON.parse(word);
        return Array.isArray(tmp);
      } catch (e) {
        return false;
      }
    },

    /**
     * @description: 判断字符串是否是一个图片地址
     * @param {String} word
     * @return {Boolean}
     */
    checkIfImage(word) {
      if(typeof word !=='string') return false;

      let regex = /^(https:\/\/|http:\/\/|\/\/).+(.jpg|.jpeg|.png|.gif)$/i;
      return regex.test(word);
    },

    /**
     * @description: 展示数组数据
     * @param {String|Array} info 信息
     */
    showArray(info) {
      if (!Array.isArray(info)) info = JSON.parse(info);
      if (isString(info[0])) {
        info.forEach((item, index) => {
          info[index] = { none: item };
        });
      }
      let keys = Object.keys(info[0]);
      console.log(info, "show array dialog");
      this.info = info;
      this.keys = keys;
      this.infoDataShow = true;
    },

    /**
     * @description: 关闭数组查看弹窗
     */
    hideArray() {
      this.infoDataShow = false;
      this.info = [];
      this.keys = [];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.reload();
    });
  },
};
</script>

<style lang="less" scoped>
.data-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .etl-m-table {
    flex: 1;
    .tip-content {
      max-width: 300px;
    }
  }
  .etl-m-page {
    text-align: center;
  }
}
</style>