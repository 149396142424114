/*
 * @Author: ttheitao
 * @Description: ETL连接验证器
 * @Date: 2022-09-06 16:00:50
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-09-09 10:56:07
 */

import Constant from "../Constant";

export default class EtlJoinValidator {
  /**
   * @description: 构造函数
   * @param {Object} graph x6
   * @param {Object} edge 边
   * @return {void}
   */
  constructor(graph, edge) {
    // x6画布对象
    this.graph = graph;
    // 当前边
    this.edge = edge;
    // 起始节点
    this.sourceCell = this.graph.getCellById(edge.source.cell);
    // 目标节点
    this.targetCell = this.graph.getCellById(edge.target.cell);
    // 错误消息
    this.errorMsg = [];
  }


  /**
   * @description: 创建链接验证器
   * @param {Object} graph x6
   * @param {Object} edge 边
   * @return {void}
   */
  static graph(graph, edge) {
    return new EtlJoinValidator(graph, edge);
  }

  /**
   * @description: 执行验证
   * @return {Boolean}
   */
  validate() {
    let pass = true;
    pass && (pass = this.checkSourceNodeType());
    pass && (pass = this.checkTargetNodeIncomings());
    pass && (pass = this.checkTargetNodeType());
    return pass;
  }

  /**
  * @description: 验证起始节点类型
  * @return {Boolean}
  */
  checkSourceNodeType() {
    let pass = true;

    switch (this.sourceCell.component) {
      case Constant.ETL_CELL_INPUT:
        break;
      case Constant.ETL_CELL_JOIN:
        break;
      case Constant.ETL_CELL_UNIONALL:
        break;
      case Constant.ETL_CELL_FILTER:
        break;
      case Constant.ETL_CELL_FIELD_CONFIG:
        break;
      case Constant.ETL_CELL_AGGREGATE:
        break;
      case Constant.ETL_CELL_OUTPUT:
        pass = false;
        this.pushErrorMsg('[输出]不能作为起始节点');
        break;
    }

    return pass;
  }

  /**
   * @description: 检测目标节点允许接入的连接数
   * @return {Boolean}
   */
  checkTargetNodeIncomings() {
    let curIncomings = this.targetCell.model?.incomings?.[this.targetCell.id];
    let pass = true;

    switch (this.targetCell.component) {
      case Constant.ETL_CELL_INPUT:
        pass = false;
        this.pushErrorMsg(`[${this.targetCell.data.label}]不支持被连接`);
        break;
      case Constant.ETL_CELL_JOIN:
        if (curIncomings && curIncomings.length > 2) {
          pass = false;
          this.pushErrorMsg(`[${this.targetCell.data.label}]已存在两个连接`);
          break;
        }
        break;
      case Constant.ETL_CELL_UNIONALL:
        // 不限制连接数量
        break;
      case Constant.ETL_CELL_FILTER:
      case Constant.ETL_CELL_FIELD_CONFIG:
      case Constant.ETL_CELL_AGGREGATE:
      case Constant.ETL_CELL_OUTPUT:
        if (curIncomings && curIncomings.length > 1) {
          pass = false;
          this.pushErrorMsg(`[${this.targetCell.data.label}]已存在连接`);
        }
        break;
    }

    return pass;
  }

  /**
   * @description: 验证目标节点类型
   * @return {Boolean}
   */
  checkTargetNodeType() {
    let pass = true;
    switch (this.targetCell.component) {
      case Constant.ETL_CELL_INPUT:
        pass = false;
        this.pushErrorMsg(`[${this.targetCell.data.label}]不能作为目标节点`);
        break;
      case Constant.ETL_CELL_JOIN:
        break;
      case Constant.ETL_CELL_UNIONALL:
        break;
      case Constant.ETL_CELL_FILTER:
        break;
      case Constant.ETL_CELL_FIELD_CONFIG:
        break;
      case Constant.ETL_CELL_AGGREGATE:
        break;
      case Constant.ETL_CELL_OUTPUT:
        break;
    }

    return pass;
  }

  /**
   * @description: 
   * @param {String} msg 错误消息
   * @return {void}
   */
  pushErrorMsg(msg) {
    this.errorMsg.push(msg);
  }

  /**
   * @description: 获取错误消息
   * @return {String}
   */
  getErrorMsg() {
    return this.errorMsg.length ? this.errorMsg[0] : '';
  }
}