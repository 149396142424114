<!--
 * @Author: ttheitao
 * @Description: 执行边
 * @Date: 2022-07-18 23:22:40
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-20 10:57:37
-->
<template>
  <div>执行边面板</div>
</template>

<script>
export default {
  name: "PanelRunEdge",
};
</script>

<style></style>
