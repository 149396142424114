<!--
 * @Author: ttheitao
 * @Description: 多源合并
 * @Date: 2022-07-17 17:07:42
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-09-21 17:14:54
-->

<template>
  <div class="etl-cell-container">
    <div class="content">
      <i class="iconfont iconduoyuanhebing"></i>
      {{ nodeData.label }}
    </div>
    <el-drawer
      class="etl-cell-panel etl-cell-union-all-panel"
      :visible.sync="showPanel"
      :append-to-body="panel.modalAppendToBody"
      :modal="panel.modal"
      :direction="panel.direction"
      :size="panel.size"
      :show-close="panel.showClose"
      @close="onDrawerClose"
    >
      <template slot="title">
        <div class="type">
          <i class="iconfont iconduoyuanhebing"></i>
          多源合并
        </div>
        <div class="name">
          <span>节点名称：</span>
          <el-input v-model="nodeData.label"></el-input>
        </div>
      </template>
      <div class="main">
        <div class="union-content">
          <div class="objects" ref="objects">
            <div class="item">合并结果</div>
            <!-- 所有的数据源名称在这里 -->
            <div
              class="item"
              v-for="item in nodeData.objects"
              :key="'ob' + item.id"
            >
              <el-tooltip :content="item.name" placement="top">
                <span>{{ item.name }}</span>
              </el-tooltip>
            </div>
          </div>
          <div class="maps" ref="maps">
            <!-- 所有的列名 -->
            <div class="names">
              <div
                class="item"
                v-for="(item,index) in nodeData.objectFields"
                :key="'title' + item.code"
              >
                <el-tooltip :content="item.name" placement="top">
                  <span>{{ item.name }}</span></el-tooltip
                >
                <i class="edit-icon el-icon-edit-outline" @click.stop="onFieldName(index,item.name)"></i>
              </div>
            </div>
            <div
              class="rows"
              v-for="item in nodeData.objects"
              :key="'orows' + item.id"
            >
              <!-- 所有的里面的字段对应关系 -->
              <div
                :class="field.maps[item.id] ? 'item active' : 'item'"
                v-for="(field,index) in nodeData.objectFields"
                :key="'frows' + field.code + item.id"
                @click.stop="onOpenField(item.id,index)"
              >
                <!-- {{ field.maps[item.id] }} -->
                <el-tooltip
                  :content="getNodeFieldName(item.id, field.maps[item.id])"
                  placement="top"
                  ><span>{{getNodeFieldName(item.id, field.maps[item.id])}}</span></el-tooltip
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 字段对应选择弹窗 -->
    <el-dialog
      class="etl-choose-etl"
      :title="'选择字段：' + nodeData.objectFields[this.dealFieldIndex].name "
      v-if="showFieldMap"
      :visible.sync="showFieldMap"
      :append-to-body="true"
      width="450px"
      top="-10vh"
      :close-on-click-modal="false"
    >
      <div class="etl-choose-main" v-loading="fieldMapLoading">
        <el-input
          placeholder="搜索ETL"
          prefix-icon="el-icon-search"
          v-model="search"
        >
        </el-input>
        <!-- data sources select area -->
        <article>
          <div
            v-for="(item, index) in fieldMapList.filter((item) => !search || item.name.indexOf(search) > -1 )"
            :key="index"
            :class="item.code == nodeData.objectFields[dealFieldIndex].maps[dealObjectId] ? 'active-item' : 'item'"
            @click="onFieldSave(item)"
          >
            <i class="iconfont icona-wendangjilu"></i> {{ item.name }}</div>
        </article>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import collect from "collect.js";
import { Tooltip } from "element-ui";
import BaseOptions from "./mixins/BaseOptions.js";

export default {
  name: "EtlUnionAll",
  mixins: [BaseOptions],
  components: { "el-tooltip": Tooltip },
  data() {
    return {
      tableData: [],
      mapsDom: null,
      objectsDom: null,

      showFieldMap:false,
      fieldMapLoading: false,
      fieldMapList: [],
      search:"",
      dealObjectId:"",
      dealFieldIndex:null
    };
  },
  watch: {
    showPanel: function (newVal) {
      this.$nextTick(() => {
        if (newVal && !this.mapsDom) {
          this.mapsDom = this.$refs["maps"];
          this.objectsDom = this.$refs["objects"];

          this.mapsDom.addEventListener("scroll", this.mapsScrollChange, true);
          this.objectsDom.addEventListener(
            "scroll",
            this.objectsScrollChange,
            true
          );
        } else {
          this.mapsDom &&
            this.mapsDom.removeEventListener(
              "scroll",
              this.mapsScrollChange,
              true
            );
          this.mapsDom = null;
          this.objectsDom &&
            this.objectsDom.removeEventListener(
              "scroll",
              this.objectsScrollChange,
              true
            );
          this.objectsDom = null;
        }
      });
    },
  },
  methods: {
    mapsScrollChange() {
      this.objectsDom.scrollTop = this.mapsDom.scrollTop;
    },
    objectsScrollChange() {
      this.mapsDom.scrollTop = this.objectsDom.scrollTop;
    },

    /**
     * @description: 根据节点和字段code换取名称
     * @param {String} nodeId 节点id
     * @param {String} fieldCode 字段code
     * @return {String} 返回的名称
     */
    getNodeFieldName(nodeId, fieldCode) {
      const node = this.graph.getCellById(nodeId);
      if (node?.data?.objectFields.length) {
        let field = collect(node.data.objectFields)
          .where("code", fieldCode)
          .first();
        if (field) return field.name;
      }
      return fieldCode;
    },

    /**
     * @description: 修改字段名称
     * @param {Number} index 字段的序号
     * @param {String} name 原始字段名称
     */    
    onFieldName(index,name){
      this.$prompt("", "修改字段名称", {
        inputValue: name,
      })
        .then(({ value }) => {
          this.nodeData.objectFields[index].name = value;
        })
        .catch(() => {});
    },

    /**
     * @description: 打开选择字段的弹窗
     * @param {String} objectId 对象的id
     * @param {Nubmer} index 字段的顺序号
     */    
    onOpenField(objectId,index){
      this.dealObjectId = objectId;
      this.dealFieldIndex = index;
      // 找到该对象的字段
      const object = this.graph.getCellById(objectId);
      this.fieldMapList = object.data.objectFields;
      this.showFieldMap = true;
    },

    /**
     * @description: 字段列表弹窗中选择了字段
     * @param {Object} field 选择的字段数据
     */    
    onFieldSave(field){
      this.nodeData.objectFields[this.dealFieldIndex].maps[this.dealObjectId] = field.code;
      this.showFieldMap = false;
    }
  },
};
</script>

<style lang="less">
.etl-cell-union-all-panel {
  .main {
    padding: 15px;
    .sticky-title {
      position: sticky;
      top: 0;
      background-color: white;
    }
    .union-content {
      flex: 1;
      width: 100%;
      display: flex;
      .objects {
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          scrollbar-width: none !important;
          display: none;
        }
        .item {
          margin-bottom: 15px;
          width: 150px;
          height: 50px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 10px;
          border: 1px solid #d9d9d9;
          box-sizing: border-box;

          &:last-child {
            margin-bottom: 7px;
          }
        }
      }
      .maps {
        flex: 1;
        overflow: auto;
        box-sizing: border-box;
        .names,
        .rows {
          min-width: 100%;
          margin-bottom: 15px;
          display: inline-flex;
          box-sizing: border-box;
          &:last-child {
            margin-bottom: 0px;
          }
          .item {
            width: 130px;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            border-top: 1px solid #d9d9d9;
            border-right: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            box-sizing: border-box;
            span {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .edit-icon {
              font-size: 18px;
              &:hover {
                color: #5a9cf3;
              }
            }
          }
        }
        .rows {
          .item {
            background-color: #f4f6f9;

            &.active {
              background-color: #e8f7f6;
            }
          }
        }
      }
    }
  }
}
.etl-choose-main {
  height: 100%;
  .item {
    padding: 12px;
    &:hover {
      color: #409eff;
      background-color: #e8f3fe;
    }
  }
  .active-item {
    padding: 12px;
    color: #409eff;
    background-color: #e8f3fe;
  }
  article {
    margin-top: 10px;
    overflow: auto;
    height: 600px;
    padding-top: 5px;
  }
  .etl-choose-page {
    margin-top: 5px;
    text-align: center;
  }
}
</style>
