import Constant from "./Constant";
import EtlEventHandler from "./EventHandler/EtlEventHandler";
import Util from "./Util";
export default class EventManager {
  base = null;
  // x6实例
  graph = null;
  // vue实例
  vm = null;

  /**
   * @description: 构造函数
   * @param {*} base 图编辑器实例对象
   * @return {*}
   */
  constructor(base) {
    this.base = base;
    this.vm = this.base.vm;
    this.graph = this.base.graph;
    this.register();
  }

  /**
   * @description: 注册事件
   * @return {*}
   */
  register() {
    this.cellEvent(); // 单元事件
    this.modelEvent(); // 画布事件
    this.keyboardEvent(); // 键盘事件
    this.selectionEvent(); // 选区事件
  }

  /**
   * @description: 单元格事件
   * @return {*}
   */
  cellEvent() {
    // 节点 鼠标进入
    this.graph.on('node:mouseenter', () => {
      this.showPorts();
    });

    // 节点 鼠标离开
    this.graph.on('node:mouseleave', () => {
      this.showPorts(false);
    });

    // 边 连接后
    this.graph.on('edge:connected', (args) => {
      if (this.base.type === Constant.GRAPH_TYPE_ETL) {
        EtlEventHandler.edgeConnected(this.graph, args);
      }
    });

    // 节点 添加时
    this.graph.on('node:added', (args) => {
      // TODO 临时处理节点创建逻辑
      const component = args.cell.component;
      const obuuid = this.vm.$route.query?.obuuid;
      if (args.cell.shape === 'vue-shape' && ['Start', 'End', 'EtlOutput'].includes(component)) {
        const cells = this.graph.getCells();
        for (let i in cells) {
          const cell = cells[i];
          if (cell.shape === 'vue-shape' && cell.component === component && cell.id !== args.cell.id) {
            this.graph.removeNode(args.cell);
            let arr = {
              Start: '开始',
              End: '结束',
              EtlOutput: '输出',
            };
            return this.vm.$message.error(`${arr[component]}节点已存在`);
          }
        }
      }

      if (obuuid && args.cell.shape === 'vue-shape' && ['EtlInput'].includes(component)) {
        const cells = this.graph.getCells();
        for (let i in cells) {
          const cell = cells[i];
          if (cell.shape === 'vue-shape' && cell.component === component && cell.id !== args.cell.id) {
            this.graph.removeNode(args.cell);
            return this.vm.$message.error(`输入节点已存在`);
          }
        }
      }
      // 处理节点新增后
      if (this.base.type === Constant.GRAPH_TYPE_ETL) {
        EtlEventHandler.nodeAdded(this.graph, args);
      }
    });

    // 单元 双击
    this.graph.on('cell:click', (args) => {
      // TODO ETL暂时不需要配置edge信息
      if (args.cell.isNode()) {
        let vm = Util.getVmByCell(this.graph, args.cell);
        if (vm) {
          vm.openPanel();
        } else {
          this.vm.openPanel(args.cell.shape);
        }
      }
    });

    // 节点 移动时
    this.graph.on('node:moving', () => {
      this.showPorts(false);
    });

    // 开始移动节点时
    this.graph.on('node:move', (args) => {
      const vm = Util.getVmByCell(this.graph, args.cell);
      vm && vm.setMovingStatus(true);
    });

    // 移动节点后
    this.graph.on('node:moved', (args) => {
      const vm = Util.getVmByCell(this.graph, args.cell);
      if (vm && vm.hasPanel) {
        setTimeout(() => {
          vm.setMovingStatus(false);
        }, 0);
      }
    });

    // 节点删除后
    this.graph.on('node:removed', (args) => {
      if (args.cell.parent) {
        args.cell.parent(args.cell);
      }

      // 处理节点删除后对后节点的影响
      if (this.base.type === Constant.GRAPH_TYPE_ETL) {
        EtlEventHandler.nodeRemoved(this.graph, args);
      }
    });

    // 边删除后
    this.graph.on('edge:removed', (args) => {
      // 处理边删除时对后节点的影响
      if (this.base.type === Constant.GRAPH_TYPE_ETL) {
        EtlEventHandler.edgeRemoved(this.graph, args);
      }
    });

    // 单元data发生改变
    this.graph.on('cell:change:data', (args) => {
      // 处理起始节点data发生变化时对后对节点的影响
      if (this.base.type === Constant.GRAPH_TYPE_ETL) {
        EtlEventHandler.cellChangeData(this.graph, args);
      }
    })
  }

  /**
   * @description: 画布事件
   * @return {*}
   */
  modelEvent() {
    /**
     * @description: 空白处右键事件
     * @return {void}
     */
    this.graph.on("blank:mousedown", () => {

    });
  }

  /**
   * @description: 键盘事件
   * @return {*}
   */
  keyboardEvent() {
    // 删除
    this.graph.bindKey('delete', () => {
      this.base.deleteCellsWithSelected();
    });

    // 复制
    this.graph.bindKey('ctrl+c', () => {
      const cells = this.graph.getSelectedCells();
      if (cells.length) {
        this.graph.copy(cells);
      }
      return false;
    })

    // 粘贴
    this.graph.bindKey('ctrl+v', () => {
      this.base.isPasting = true;
      if (!this.graph.isClipboardEmpty()) {
        const cells = this.graph.paste({ offset: 32 });
        this.graph.cleanSelection();
        this.graph.select(cells);

        this.base.autoFixId(cells);
      }
      this.base.isPasting = false;
      return false;
    })
  }

  /**
   * @description: 选区事件
   * @return {*}
   */
  selectionEvent() {
    this.graph.on('cell:selected', (args) => {
      this.base.curCell = args.cell;
      this.showPorts(false);
    });

    this.graph.on('cell:unselected', (args) => {
      this.base.curCell = null;
      const vm = Util.getVmByCell(this.graph, args.cell);
      vm && vm.showPanel && vm.closePanel();
    });
  }

  /**
   * @description: 控制连接桩显示/隐藏
   * @param {Boolean} show 是否显示
   * @return {*}
   */
  showPorts(show = true) {
    const container = this.vm.$el;
    const ports = container.querySelectorAll('.x6-port-body');

    for (let i = 0, len = ports.length; i < len; i = i + 1) {
      ports[i].style.visibility = show ? 'visible' : 'hidden';
    }
  }
}